import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	Output,
	ViewChild,
	EventEmitter,
	ChangeDetectionStrategy,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { Entity } from '../../../state/entities/entities.model';

/**
 * Button Select Component
 * This component implements a radio / checkbox form UI in leiu of Material having this built in
 */
@Component({
	selector: 'app-button-select',
	templateUrl: './button-select.component.html',
	styleUrls: ['./button-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonSelectComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('selectButton', { read: ElementRef }) selectButtonComponent: ElementRef;
	@ViewChild(MatSelect) matSelect: MatSelect;

	@Input() options: Entity[];
	@Input() currentValue: any;
	@Input() selectLayout: 'singles' | 'multiples' | 'groups' = 'singles';
	@Input() buttonText: string;
	@Input() showExpandButton: boolean;
	@Input() buttonColor: string = 'secondary';
	@Input() buttonSize: string = 'small';
	@Input() iconName: string; // UIL Metron system
	@Input() entityValue; // Leave blank to use the whole entity as the value
	@Input() classes: string;
	@Input() debounceTime = 50;
	@Input() applyButton = false;
	@Output() valueChanged: EventEmitter<string> = new EventEmitter();
	@Output() applied: EventEmitter<string> = new EventEmitter<string>();
	@Output() closed: EventEmitter<string> = new EventEmitter<string>();

	overlayEl: Element;
	public itemCtrl: FormControl;

	private scrollTopBeforeSelection: number; // Hack to handle mat select jumping after selection
	private readonly _unsubscribe$: Subject<void> = new Subject();
	private _isOpen = false;

	constructor() {}

	ngOnInit(): void {
		// Create a single form control to govern the selection value.
		this.itemCtrl = new FormControl(this.currentValue);
	}

	ngAfterViewInit(): void {
		const el = (this.selectButtonComponent.nativeElement as Element).querySelector('.mat-button-focus-overlay');

		//console.log('Button Select Overlay', el);

		if (el) {
			this.overlayEl = el;
		}

		// Subscribe to the control changes so we can debounce it if needed
		//console.log('Subscribing to FormControl changes');
		this.itemCtrl.valueChanges
			.pipe(
				// startWith(this.currentQuery),
				debounceTime(this.debounceTime),
				filter(() => this._isOpen),
				map((options) => {
					//console.log('Value Changed', options);
					if (!this.applyButton && !this.compareObjects(options, this.currentValue)) {
						this.valueChanged.emit(options);
					}
				}),
				takeUntil(this._unsubscribe$)
			)
			.subscribe();

		// HACK: We have to detect value changes and reset the scroll on the select so it doesn't jump.
		this.matSelect.openedChange.pipe(takeUntil(this._unsubscribe$)).subscribe((open) => {
			this._isOpen = open;

			if (open) {
				this.matSelect.panel?.nativeElement.addEventListener('scroll', this._storeScrollPosition.bind(this));
			} else {
				this.matSelect.panel?.nativeElement.removeEventListener('scroll', this._storeScrollPosition.bind(this));
			}
		});

		/* 	this.matSelect.openedChange
			.pipe( takeUntil(this.unsubscribe$) )
			.subscribe((open) => {
				if (open) {
					this.matSelect.panel?.nativeElement.addEventListener(
						'scroll',
						(event) => (this.scrollTopBeforeSelection = event.target.scrollTop)
					);
				}
			}); */

		// HACK: We have to detect value changes and reset the scroll on the select so it doesn't jump.
		/*this.matSelect.optionSelectionChanges
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe(() => {
				if (this.matSelect.panel && this.scrollTopBeforeSelection !== undefined) {
				 requestAnimationFrame(() => {
						if(this.matSelect.panel){
							this.matSelect.panel.nativeElement.scrollTop = this.scrollTopBeforeSelection;
						}
					});
				}
    	}); */

		/* 		this.matSelect.optionSelectionChanges
			.pipe( takeUntil(this.unsubscribe$) )
			.subscribe(() => {
				if (this.matSelect.panel) {
					this.matSelect.panel.nativeElement.scrollTop = this.scrollTopBeforeSelection;
				}
			}); */
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	compareWith(a: any, b: any): boolean {
		if (a.id) {
			return a?.id || a?.name === b?.id || b?.name;
		} else {
			return a === b;
		}
	}

	compareObjects(a, b): boolean {
		return JSON.stringify(a) === JSON.stringify(b);
	}

	applyValue(): void {
		this.applied.emit(this.itemCtrl.value);
		this.matSelect.close();
	}

	removeOverlay(): void {
		this.overlayEl?.classList.remove('mat-button-focus-overlay');
		this.closed.emit(this.itemCtrl.value);

		if (this.currentValue) {
			this.itemCtrl.setValue(this.currentValue);
		}
	}

	addOverlay(): void {
		this.overlayEl?.classList.add('mat-button-focus-overlay');
	}

	compareFn(c1: any, c2: any): boolean {
		if (c1 && c2) {
			if (c1.id || c2.id) {
				return c1.id === c2.id;
			} else if (c1.name || c2.name) {
				return c1.name === c2.name;
			} else {
				return c1 === c2;
			}
		} else {
			return c1 === c2;
		}
	}

	trackByFn(index, item): any {
		return item.id || item.name;
	}

	private _storeScrollPosition(event: Event): void {
		this.scrollTopBeforeSelection = (event.target as HTMLElement)?.scrollTop;
	}
}
