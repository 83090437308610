import { Column, Entity, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Program } from '../program/program.entity';
import { DeliverableEntity } from '../deliverable/deliverable.entity';

@Entity({ name: 'programStrategicBriefs' })
export class ProgramStrategicBriefEntity {
	@PrimaryGeneratedColumn('uuid', { name: 'id' }) id: string;
	@Column({ name: 'data', type: 'json' }) data: any;

	@Column({ name: 'versionNumber', type: 'int' }) versionNumber: number;
	@Column({ name: 'versionDate', type: 'timestamp' }) versionDate: Date;

	@ManyToOne(() => Program, (program) => program.programStrategicBrief)
	@JoinColumn({ name: 'program_id' })
	program: Program | string;

	@ManyToOne(() => DeliverableEntity)
	@JoinColumn({ name: 'deliverable_id' })
	deliverable: DeliverableEntity;
}
