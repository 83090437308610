import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { joinWithProp } from '../../../_core/utils/array.utils';
import { replaceMergeTags, replaceMergeTagsWithString } from '../../../_core/utils/string.utils';
import { Filter, FilterParameters } from './filter.model';
import { EntitySettings } from '../../../../../../api/src/organization/organization.settings';
import { resolveDotNotationPath } from '../../../_core/utils/object.utils';
import { getValidGroupForEndpoint } from '../../table/table.utils';
import { AppSection, AppSectionToEntitySettingMap, Entities } from '../../global/global.model';

export function getFormButtonCurrentValueText(filter: Filter, formGroup: FormGroup, entitySettings: EntitySettings) {
	if (filter.extra?.buttonSelect?.iconOnly) {
		return undefined;
	}

	// If we already have text, use it and apply any merge tags from the form value
	if (filter.extra?.buttonSelect?.buttonText) {
		if (filter.extra?.buttonSelect?.buttonTextMask) {
			const mask = resolveDotNotationPath(filter.extra?.buttonSelect.buttonTextMask, { filter, formGroup: formGroup.value });
			const maskedText = resolveDotNotationPath(mask, entitySettings);
			if (maskedText) return replaceMergeTagsWithString(filter.extra?.buttonSelect.buttonText, maskedText);
		}

		return replaceMergeTags(filter.extra?.buttonSelect.buttonText, { filter, formGroup: formGroup.value });
	}

	if (filter.extra?.buttonSelect?.selectLayout === 'multiples') {
		return (
			joinWithProp(formGroup.value[filter.slug], 'name', ', ', filter.extra?.buttonSelect?.maxButtonTextOptions || 2) ||
			resolveDotNotationPath(filter.extra.maskPath, entitySettings) ||
			filter.extra?.buttonSelect?.emptyText ||
			'Select ' + filter.name
		);
	} else if (filter.type === 'date-range') {
		// If we have a date range, format the dates and show them together
		// If we don't, use the default text.

		let start = formGroup.value.start;
		let end = formGroup.value.end;

		if (!start && !end) {
			return 'Select ' + filter.name;
		}

		if (start) {
			start = formatDate(start, 'MMM d, yyyy', environment.locale);
		}

		if (end) {
			end = formatDate(end, 'MMM d, yyyy', environment.locale);
		}

		return (start || '?') + ' - ' + (end || '?');
	} else {
		let name = filter.extra?.buttonSelect?.emptyText || 'Select ' + filter.name;

		if (formGroup.value[filter.slug] && formGroup.value[filter.slug].id) {
			name = formGroup.value[filter.slug].name;
		}

		if (filter.extra?.maskPath) {
			name = resolveDotNotationPath(filter.extra.maskPath, entitySettings) || name;
		}

		return name;
	}
}

/**
 * Override filter mask path by app section (e.g. program -> mediaPlan)
 * @param filter
 * @param appSection
 * @param entitySettings
 * @param entityName
 */
export function overrideFilterMaskPathByAppSection(
	filter: Filter,
	appSection: AppSection,
	entitySettings: EntitySettings,
	entityName: Entities = 'program'
): Filter {
	const entitySetting = AppSectionToEntitySettingMap[appSection];
	if (!entitySetting) {
		return filter;
	}
	// Let's not override the mask path if it's already set
	if (appSection === 'activation') {
		return filter;
	}

	const updateMaskPath = (maskPath: string) => maskPath.replace(entityName, entitySetting);
	if (filter.options?.length) {
		filter = {
			...filter,
			options: filter.options.map((option) => {
				if (option.extra?.maskPath) {
					const updatedMaskPathForFilterOption = updateMaskPath(option?.extra?.maskPath);
					const foundMaskPath = resolveDotNotationPath(updatedMaskPathForFilterOption, entitySettings);
					// If we didn't find a mask path or the mask path is the same as the original, return the filter
					if (!foundMaskPath && updatedMaskPathForFilterOption === option.extra.maskPath) {
						return option;
					}
					return {
						...option,
						extra: {
							...option.extra,
							maskPath: updatedMaskPathForFilterOption,
						},
					};
				}
				return option;
			}),
		};
	}
	if (filter.extra?.maskPath) {
		const updatedMaskPathForFilter = updateMaskPath(filter.extra.maskPath);
		const foundMaskPath = resolveDotNotationPath(updatedMaskPathForFilter, entitySettings);
		// If we didn't find a mask path or the mask path is the same as the original, return the filter
		if (!foundMaskPath || updatedMaskPathForFilter === filter.extra.maskPath) {
			return filter;
		}

		filter = {
			...filter,
			name: foundMaskPath || filter?.extra?.emptyText || '',
			extra: {
				...filter.extra,
				maskPath: updatedMaskPathForFilter,
			},
		};

		return filter;
	}

	return filter;
}

export function doesFilterMatchEndpoint(filter: Filter, endpoint: string) {
	let match = false;

	// Filter category should be 'overall' or have the same cat as one of the included entities
	if (filter.category === 'overall' || filter.category === endpoint) {
		match = true;
	}

	// If the filter has a matchEnpoint, it can only be true if it matches
	// console.log('filter.matchEndpoint', filter.matchEndpoint, endpoint);
	if (filter.matchEndpoint) {
		if (filter.matchEndpoint === endpoint) {
			match = true;
		} else {
			match = false;
		}
	}

	return match;
}

/**
 * Extract filter values from a form group
 *
 * @param filters - List of selected filters
 * @param formGroup - Form group containing selected filter values
 * @returns selected filter values
 */
export function getFilterParametersBySlug(filters: Filter[], formGroup: FormGroup): FilterParameters {
	let filterFormObj: FilterParameters = {};
	const formGroupValue = formGroup.value;

	filters.forEach((filter) => {
		const { slug } = filter;
		const filterValue = formGroupValue?.[slug];

		if (filterValue !== undefined) {
			filterFormObj[slug] = filterValue;
		}
	});

	// Post process filters
	const defaultGroupOption = getDefaultGroupOptionForEndpoint(filterFormObj);
	// Override the filterFormObj with the default group option
	if (defaultGroupOption) {
		filterFormObj = { ...filterFormObj, ...defaultGroupOption };
	}

	return filterFormObj;
}

/**
 * Get the default group option by selected endpoint
 *
 * @param filterFormObj - The accumulated filtered form values.
 * @returns An object containing updates to filteredFormValue, or null if no updates.
 */
export function getDefaultGroupOptionForEndpoint(filterFormObj: FilterParameters): Partial<FilterParameters> | null {
	if (filterFormObj?.include?.defaultGroupId && filterFormObj?.include?.id) {
		const validGroup = getValidGroupForEndpoint(filterFormObj.include.id, filterFormObj?.include?.defaultGroupId);

		if (validGroup) {
			return { groups: validGroup };
		}
	}

	return null;
}
