<div class="get-started-container">
	<div class="content-container">
		<h2 class="title">{{ title }}</h2>
		<p class="description" *ngIf="description">{{ description }}</p>

		<div class="actions">
			<button mat-flat-button class="button-extra-small" color="primary" (click)="actionClicked.emit()" *ngIf="actionTitle">
				{{ actionTitle }}
			</button>
			<ng-content></ng-content>
		</div>
	</div>
</div>
