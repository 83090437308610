import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { GlobalService } from '../../../../state/global/global.service';
import { FileService } from '../file.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-file-microsoft-viewer',
	templateUrl: './file-microsoft-viewer.component.html',
	styleUrls: ['./file-microsoft-viewer.component.scss'],
})
export class FileMicrosoftViewerComponent implements OnInit, OnDestroy {
	@Input() file: PublicFile;
	@Input() maxH: boolean = false;
	iframeUrl: string;

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(private readonly fileService: FileService, private readonly globalService: GlobalService) {}

	ngOnInit(): void {
		this.getTemporaryFileURL(this.file);
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	getTemporaryFileURL(file: PublicFile): void {
		this.fileService
			.getTemporaryFileURL(file.path)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(response) => {
					this.iframeUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(response.url);
				},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}
}
