import { ProgramService } from './../../../state/entities/program/program.service';
import { Program, ProgramStrategicBrief } from './../../../state/entities/program/program.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../state/global/global.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { StrategicBriefDialogDefaultComponent } from './types/default/default.component';
import { delay, filter, first, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GlobalQuery } from '../../../state/global/global.query';
import { StrategicBriefViewComponent } from '../strategic-brief-view/strategic-brief-view.component';
import { FileService } from '../../../state/entities/file/file.service';
import { FileUploadRequest } from '../../../state/entities/file/file.model';
import { Observable, of, timer, pipe } from 'rxjs';
import { Milestones } from '../../../state/entities/milestone/milestones.model';
import { Deliverable } from '../../../state/entities/deliverable/models/deliverable.model';
import {
	strategicBriefSupportedBriefTypes,
	StrategicBriefSupportedType,
	strategyBriefTitleByDeliverable,
} from './types/default/strategic-brief-default.data';
import { Router } from '@angular/router';
import { TacticService } from '../../../state/entities/tactic/tactic.service';
import { AddTacticDialogResponse } from '../../../state/entities/tactic/add-tactic-dialog/add-tactic-dialog.component';
import { AppSection } from '../../../state/global/global.model';

@Component({
	selector: 'app-strategic-brief-dialog',
	templateUrl: 'strategic-brief-dialog.component.html',
	styleUrls: ['strategic-brief-dialog.component.scss'],
})
export class StrategicBriefDialogComponent implements OnInit {
	program: Program;

	loading?: boolean;

	showInvalidFormError = false;

	supportedBriefs: StrategicBriefSupportedType[] = strategicBriefSupportedBriefTypes;

	briefTitle = strategyBriefTitleByDeliverable;

	@ViewChild(StrategicBriefDialogDefaultComponent) defaultComponent: StrategicBriefDialogDefaultComponent;
	@ViewChild('dynamic', { read: ViewContainerRef }) private viewRef: ViewContainerRef;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public readonly dialogData: {
			readonly program: Program;
			readonly deliverable: Deliverable;
			readonly section: AppSection;
		},
		private readonly dialogRef: MatDialogRef<StrategicBriefDialogComponent>,
		private readonly programService: ProgramService,
		private readonly globalService: GlobalService,
		private readonly cdr: ChangeDetectorRef,
		private readonly globalQuery: GlobalQuery,
		private readonly vcr: ViewContainerRef,
		private readonly fileService: FileService,
		private readonly tacticService: TacticService,
		private readonly router: Router,
		private readonly dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.program = this.dialogData.program;
	}

	onMilestonesUpdate(milestones: Milestones[]): void {
		this.programService.update(this.program.id, { milestones }, true);
		this.program = { ...this.program, milestones };
	}

	onSave() {
		this.showInvalidFormError = false;

		console.log(this.defaultComponent.formValid, this.defaultComponent.form.value, this.defaultComponent.form);

		//   if (!this.strategicBriefType || this.strategicBriefType === StrategicBriefTypeEnum.DEFAULT) {
		this.defaultComponent.form.markAllAsTouched();
		if (!this.defaultComponent.formValid) {
			this.showInvalidFormError = true;
			return;
		}

		const dto = this.defaultComponent.form.value;

		/*   } else {
      this.showInvalidFormError = true;
      return;
    } */

		this.loading = true;

		this._preparePdfData({
			...this.program.programStrategicBrief,
			data: dto,
			deliverable: this.dialogData.deliverable,
			versionNumber: (this.program.programStrategicBrief?.versionNumber || 0) + 1,
		})
			.pipe(
				first(),
				switchMap((fileResponse) => {
					return this.programService.updateProgramStrategicBrief(
						this.program.id,
						dto,
						fileResponse.body.id,
						this.dialogData.deliverable.id
					);
				})
			)
			.subscribe(
				(response) => {
					this.globalService.triggerSaveMessage();
					this.dialogRef.close({ ...response });
				},
				(err: HttpErrorResponse) => {
					this.globalService.triggerErrorMessage(err);
				},
				() => {
					this.loading = false;
					this.cdr.detectChanges();
				}
			);
	}

	onAddTactic(): void {
		import('../../../state/entities/tactic/add-tactic-dialog/add-tactic-dialog.component').then((m) =>
			this.dialog
				.open(m.AddTacticDialogComponent, {
					data: {
						program: this.program,
						hideSaveAndView: true,
					},
					disableClose: false,
				})
				.afterClosed()
				.subscribe((response: AddTacticDialogResponse) => {
					if (!response) {
						return;
					}

					this.tacticService.create(response.tactic, true).subscribe(
						(createResponse) => {
							this.program = {
								...this.program,
								tactics: [...(this.program?.tactics || []), createResponse],
							};
							this.programService.update(this.program.id, { tactics: this.program.tactics }, true);
							this.cdr.detectChanges();
							this.globalService.triggerSaveMessage();
						},
						(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
					);
				})
		);
	}

	onEditBudget(): void {
		import('../../../state/entities/program/program-quick-edit-dialog/program-quick-edit-dialog.component').then((m) =>
			this.dialog
				.open(m.ProgramQuickEditDialogComponent, {
					data: {
						entity: this.program,
						entityType: 'Program',
						column: {
							name: 'Allocated Budget',
						},
						program: this.program,
						propertyType: 'allocated-budget',
						dismissWithUpdatedEntity: true,
					},
					disableClose: true,
					panelClass: ['fullscreen', 'background-color', 'max-width-lg'],
				})
				.afterClosed()
				.subscribe((response) => {
					if (response) {
						this.program = {
							...this.program,
							budgetAllocations: response.budgetAllocations,
						};
						this.cdr.detectChanges();
					}
				})
		);
	}

	private _preparePdfData(brief: ProgramStrategicBrief): Observable<any> {
		// const type = this.strategicBriefType;
		this.viewRef.clear();

		let obs$ = of(null);

		//if (!type || type === StrategicBriefTypeEnum.DEFAULT) {
		obs$ = this._generateDefaultPdf(brief);
		//}

		return obs$.pipe(
			tap(() => {
				this.viewRef.clear();
			})
		);
	}

	private _generateDefaultPdf(brief: ProgramStrategicBrief): Observable<any> {
		const compRef = this.vcr.createComponent(StrategicBriefViewComponent);
		compRef.instance.program = {
			...this.program,
			programStrategicBrief: brief,
		};
		compRef.instance.exportView = true;

		return compRef.instance.dataSubscribed.pipe(
			first(),
			tap(() => {
				compRef.changeDetectorRef.detectChanges();
			}),
			delay(0),
			switchMap(() => {
				const html = compRef.location.nativeElement;
				compRef.destroy();
				return this._generatePdf(html, brief);
			})
		);
	}

	private _generatePdf(html: HTMLElement, brief: ProgramStrategicBrief): Observable<any> {
		const label = strategyBriefTitleByDeliverable(this.dialogData.deliverable);

		const name = `${label} - Version ${brief.versionNumber}`;
		return this.fileService.generatePdf(html, name).pipe(
			first(),
			switchMap((response) => {
				const fileReq: FileUploadRequest = {
					name,
					category: { id: 'strategyDocument', name: 'Strategy Document' },
					program: this.program,
					data: response,
				};
				return this.fileService.upload(fileReq, this.program.id, 'program').pipe(
					filter((event) => event instanceof HttpResponse),
					take(1)
				);
			})
		);
	}
}
