<ng-container>
	<div #exportContainer id="geometrics-objectives">
		<div *ngIf="objectives; else emptyState">
			<!-- Macro and Shopper Target-->
			<div class="row" fxLayout="row">
				<div id="macroObjectives" class="group" *ngIf="objectives.macroObjectives?.length">
					<h3 class="eyebrow">Macro Objective</h3>

					<div class="answers">
						<div class="answer" *ngFor="let objective of objectives.macroObjectives">
							<h3 class="large">{{ objective.label }}</h3>
							<p class="caption">{{ objective.caption }}</p>
						</div>
					</div>
				</div>

				<div id="shopperTarget" class="group" *ngIf="objectives.shopperTarget?.length">
					<h3 class="eyebrow">Shopper Target</h3>

					<div class="answers">
						<div class="answer" *ngFor="let objective of objectives.shopperTarget">
							<h3 class="large">{{ objective.label }}</h3>
							<p class="caption">{{ objective.caption }}</p>
						</div>
					</div>
				</div>
			</div>

			<!-- Shopper Objectives -->
			<div *ngIf="objectives.subObjectives?.length" class="content-row kpi-container">
				<div class="title-row">
					<h3>Shopper Objectives</h3>
				</div>
				<div class="row items items-{{ objectives.subObjectives.length }}">
					<div class="item" *ngFor="let objective of objectives.subObjectives">
						<div class="title-row">
							<div class="left">
								<h4>{{ objective.label }} <i class="uil-star inline"></i></h4>
							</div>
						</div>
						<p class="caption">{{ objective.caption }}</p>
					</div>
				</div>
			</div>

			<!-- Performance Objectives -->
			<div *ngIf="objectives.performanceObjectives?.length" class="content-row kpi-container">
				<div class="title-row">
					<h3>Performance Objectives</h3>
				</div>
				<div class="row items items-{{ objectives.performanceObjectives.length }}">
					<div class="item" *ngFor="let objective of objectives.performanceObjectives">
						<div class="title-row">
							<div class="left">
								<h4>{{ objective.label }} <i class="uil-star inline"></i></h4>
							</div>
						</div>
						<p class="caption">{{ objective.caption }}</p>
					</div>
				</div>
			</div>

			<!-- KPIs -->
			<div class="content-row kpi-container">
				<div class="title-row">
					<h3>KPIs</h3>
				</div>
				<div class="row items items-{{ objectives.kpis.length }}">
					<div class="item" *ngFor="let kpi of objectives.kpis | orderBy: 'label'">
						<div class="title-row">
							<div class="left">
								<h4>{{ kpi.label }} <i class="uil-star inline"></i></h4>
							</div>
							<div class="right">
								<span class="category">{{ kpi.category }}</span>
							</div>
						</div>
						<p class="caption">{{ kpi.caption }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<ng-template #emptyState>
	<app-empty-state title="No Objectives have been added yet"></app-empty-state>
</ng-template>
