import { Pipe, PipeTransform } from '@angular/core';
import { resolveDotNotationPath } from '../../_core/utils/object.utils';
import { Column, TableRow } from '../../state/table/table.model';
import { isObject } from '../../_core/utils/types.utils';

@Pipe({
	name: 'getTableCellValue',
})
export class GetTableCellValuePipe implements PipeTransform {
	transform(column: Column, row: TableRow<any>, cellValue: string | any, expectValueProperty: boolean): any {
		let data = '';

		// Skip pagination row
		if (row.type === 'pagination') {
			return '';
		}
		if (expectValueProperty) {
			data = resolveDotNotationPath(column.path, row)?.value;
		} else {
			data = resolveDotNotationPath(column.path, row);
		}

		// FIXME temp fix for Tactic Funding Source
		if (column.id === 'tactic-funding-source' && data === '') {
			data = resolveDotNotationPath(column.path, row)?.rawValue?.name;
		}

		// For Tags, if the row is not of the correct type, return an empty string
		if (column.id.includes('tags') && !column.entityTypes.includes(row.type?.toLowerCase())) {
			return '';
		}

		// Fallback if the value is an object
		if (column.id === 'id' && isObject(data)) {
			data = (data as any).value;
		}

		return data || cellValue || '';
	}
}
