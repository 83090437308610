import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BudgetAllocation } from './budget-allocation.model';

export interface BudgetAllocationState extends EntityState<BudgetAllocation> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'budget-allocation' })
export class BudgetAllocationStore extends EntityStore<BudgetAllocationState> {
	constructor() {
		super();
	}
}
