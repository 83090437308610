import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormPropertyUpdateObject } from '../../../../state/global/global.model';
import { Observable, Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalService } from '../../../../state/global/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MilestonesQuery } from '../milestones.query';
import { Milestones, MilestonesStatuses, MilestoneTypes } from '../milestones.model';
import { MilestonesService } from '../milestones.service';
import { Program } from '../../program/program.model';
import { first, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-milestone-dialog',
	templateUrl: './milestone-dialog.component.html',
	styleUrls: ['./milestone-dialog.component.scss'],
})
export class MilestoneDialogComponent implements OnInit, OnDestroy {
	public loading$: Observable<boolean>;
	public total: number;
	public statuses = MilestonesStatuses;
	public types = MilestoneTypes;
	public form: FormGroup;

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly globalService: GlobalService,
		private readonly milestonesQuery: MilestonesQuery,
		private readonly milestonesService: MilestonesService,
		public dialogRef: MatDialogRef<MilestoneDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: { milestone: Milestones; tactic?: any; program?: Program; createMode: boolean }
	) {
		this.loading$ = this.milestonesQuery.selectLoading();
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	ngOnInit(): void {
		console.warn(this.data.milestone);
		this.milestonesService.setLoading(false);
	}

	create(): void {
		const obj = {
			id: this.data.milestone.id,
			name: this.data.milestone.name,
			status: this.data.milestone.status,
			description: this.data.milestone.description,
			start: this.data.milestone.start,
			end: this.data.milestone.end,
			type: this.data.milestone.type,
			created: this.data.milestone.created,
			authorId: this.data.tactic?.author.id ? this.data.tactic.author.id : this.data.program.author.id,
			tactic: this.data.tactic?.id,
			program: this.data.tactic?.programId ? this.data.tactic.programId : this.data.program.id,
		};

		console.log(this.data.milestone);

		this.milestonesService
			.create(this.data.tactic?.id, obj)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(response) => {
					this.data.milestone = response as Milestones;
					this.data.createMode = false;
					this.dismiss();
				},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	update(fieldName: string, ev: any): void {
		const val = ev?.value || ev?.target?.value || ev;

		const obj: Partial<Milestones> = {
			id: this.data.milestone.id,
			[fieldName]: val,
			created: this.data.milestone.created,
		};

		console.log('CREATE:', this.data.createMode);

		this.milestonesService
			.update(this.data.tactic?.id, this.data.milestone.id, obj)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(response) => {
					console.log('RESPONSE', response);
					this.data.milestone = {
						...this.data.milestone,
						...(response as Milestones),
					};
				},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	isValid(milestone: Partial<Milestones>): boolean {
		if (!milestone.name || !milestone.start || !milestone.end || !milestone.type || !milestone.status || !milestone.description) {
			return false;
		}
		return true;
	}

	dismiss(): void {
		this.dialogRef.close(this.data.milestone);
	}

	compareWithId(a: any, b: any): boolean {
		return a?.id === b?.id;
	}

	updateEntityStore(obj: FormPropertyUpdateObject): void {
		this.milestonesService.update(this.data.tactic.id, this.data.milestone.id, { [obj.fieldName]: obj.data }, true);
	}
}
