import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ActiveRouteState, RouterQuery, RouterState } from '@datorama/akita-ng-router-store';
import { environment } from '../../../../environments/environment';
import { GlobalSettings, UserRole } from '../../../state/global/global.model';
import { GlobalQuery } from '../../../state/global/global.query';
import { Profile } from '../../../state/session/session.model';
import { SessionQuery } from '../../../state/session/session.query';
import { SessionService } from '../../../state/session/session.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FilterService } from '../../../state/entities/filter/filter.service';

@Component({
	selector: 'app-sidebar-nav',
	templateUrl: './sidebar-nav.component.html',
	styleUrls: ['./sidebar-nav.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class SidebarNavComponent implements OnInit, OnDestroy {
	@Input() activeRouteState: ActiveRouteState;

	collapsedMenuShown = false;
	showPlatformPanelSidebar$: Observable<boolean>;

	public disableHome: boolean;
	public disableActivation = environment.disableActivation;
	public disablePlanning = environment.disablePlanning;
	public disableReporting;
	public fileReviewsEnabled = false;
	public showFileReviewPage = false;
	public showMediaPlanSection = false;
	public settings$: Observable<GlobalSettings>;
	public profile$: Observable<Profile>;
	public production = environment.production;
	public routerActiveState$: Observable<ActiveRouteState>;
	public onlyShowDashboard: boolean;
	public showBriefTab = false;

	public authUserRole: UserRole;

	readonly UserRole = UserRole;

	private readonly _unsubscribe$ = new Subject<void>();

	constructor(
		private readonly globalQuery: GlobalQuery,
		private readonly sessionQuery: SessionQuery,
		private readonly sessionService: SessionService,
		private readonly router: Router,
		private readonly routerQuery: RouterQuery,
		private readonly filterService: FilterService,
		private readonly renderer: Renderer2
	) {
		this.settings$ = this.globalQuery.select('settings');
		this.profile$ = this.sessionQuery.select('profile');
		this.showPlatformPanelSidebar$ = this.globalQuery
			.select(['header', 'viewMode'])
			.pipe(map(({ header, viewMode }) => header.visible && viewMode === 'default'));
		this.routerActiveState$ = this.routerQuery.select().pipe(map((state: RouterState) => state.state));
	}

	ngOnInit(): void {
		this.globalQuery.authenticatedSettings$.pipe(takeUntil(this._unsubscribe$)).subscribe((settings) => {
			this.authUserRole = this.sessionQuery.getRole();
			this.disableActivation = this.globalQuery.getOrganizationSetting('hideActivateSection') || environment.disableActivation;
			this.disablePlanning = this.globalQuery.getOrganizationSetting('hidePlanSection') || environment.disablePlanning;
			this.disableReporting = this.globalQuery.getOrganizationSetting('hideReportingSection');
			this.fileReviewsEnabled = this.globalQuery.getOrganizationSetting('fileReviewsEnabled');
			this.showFileReviewPage = this.globalQuery.getOrganizationSetting('showFileReviewPage');
			this.showMediaPlanSection = this.globalQuery.getOrganizationSetting('showMediaPlanSection');
			this.showBriefTab = this.globalQuery.getOrganizationSetting('showBriefTab');

			// TODO Review conflict
			if (this.authUserRole === UserRole.Sales || this.authUserRole === UserRole.OutsideAgency) {
				this.disableHome = true;
				this.disablePlanning = true;
				this.fileReviewsEnabled = false;
			}
			if (this.authUserRole === UserRole.Analyst) {
				this.fileReviewsEnabled = false;
				this.showFileReviewPage = false;
			}

			this.onlyShowDashboard =
				/* this.sessionQuery.getRole() === UserRole.Analyst || */ this.sessionQuery.getRole() === UserRole.Guest ? true : false;
		});
	}

	logout(): void {
		this.sessionService.logout();
		this.router.navigate(['/login']);
		setTimeout(() => {
			this.filterService.filterValues = {};
		}, 100);
	}

	showUserMenu(): void {
		this.collapsedMenuShown = true;
	}

	hideUserMenu(): void {
		this.collapsedMenuShown = false;
	}

	async toggleMenu(): Promise<void> {
		this.collapsedMenuShown = !this.collapsedMenuShown;

		const visible = await this.showPlatformPanelSidebar$.toPromise();

		if (visible) {
			document.body.classList.add('collapsed-nav');
		} else {
			document.body.classList.remove('collapsed-nav');
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}
}
