<div class="column" fxLayout="column">
	<div class="column" fxLayout="column" *ngIf="showActions">
		<div class="font-size-small text-color-primary mt-5">File Upload</div>
		<div
			*ngIf="!loader; else showLoader"
			class="upload-container mt-5"
			(click)="fileInput.click()"
			appDropFile
			(filesDropped)="onFileDrop($event)"
		>
			<p class="font-size-small">Click to upload or drop supporting files here</p>
			<input hidden type="file" multiple #fileInput (change)="onFileDrop($event.target.files)" />
		</div>
	</div>

	<app-file-list
		class="mt-5"
		*ngIf="(files$ | async)?.length > 0"
		[files]="files$ | async"
		[tactics]="program?.tactics || []"
		[entity]="program"
		[entityType]="'program'"
		(removeConfirmed)="onFileRemove($event)"
		[showActions]="showActions"
		[fileReviewsEnabledOverride]="false"
		[hideEdit]="true"
	></app-file-list>
</div>

<ng-template #showLoader>
	<div style="height: 200px; position: relative">
		<div class="loading">
			<mat-spinner [diameter]="40"></mat-spinner>
		</div>
	</div>
</ng-template>
