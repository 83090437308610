<div (submit)="update.emit(filter.slug)" [formGroup]="formGroup" [ngSwitch]="filter.type" class="wrapper {{ filter.extra?.classes }}">
	<!-- Text -->
	<mat-form-field *ngSwitchCase="'text'" class="full-width" floatLabel="always">
		<mat-label>{{ filter.name | entityFieldMask: filter.extra?.maskPath }}</mat-label>
		<input
			(keyup)="changeWithDebounce(filter.slug, $event.target.value, 750)"
			[placeholder]="filter.extra?.placeholder || filter.name | entityFieldMask: filter.extra?.maskPath"
			[value]="formGroup.value[filter.slug]"
			matInput
		/>
	</mat-form-field>

	<!-- Percentage -->
	<mat-form-field *ngSwitchCase="'percentage'" class="full-width" floatLabel="always">
		<mat-label>{{ filter.name | entityFieldMask: filter.extra?.maskPath }}</mat-label>
		<input
			(change)="percentChange(filter.slug, $event.target.value)"
			[imask]="percentMask"
			[placeholder]="filter.extra?.placeholder || filter.name | entityFieldMask: filter.extra?.maskPath"
			[value]="getPercentValue(formGroup.value[filter.slug])"
			matInput
		/>
	</mat-form-field>

	<!-- Text Area -->
	<mat-form-field *ngSwitchCase="'textarea'" class="full-width" floatLabel="always">
		<mat-label>{{ filter.name | entityFieldMask: filter.extra?.maskPath }}</mat-label>
		<textarea
			(change)="changeWithDebounce(filter.slug, $event.target.value, 10)"
			[placeholder]="filter.extra?.placeholder || filter.name | entityFieldMask: filter.extra?.maskPath"
			[value]="formGroup.value[filter.slug]"
			matInput
		></textarea>
	</mat-form-field>

	<!-- Search -->
	<mat-form-field *ngSwitchCase="'search'" class="full-width" floatLabel="always">
		<mat-label>{{ filter.name | entityFieldMask: filter.extra?.maskPath }}</mat-label>
		<input
			(keyup)="changeWithDebounce(filter.slug, $event.target.value, 750)"
			[placeholder]="filter.extra?.placeholder || 'Search for ' + (filter.name | entityFieldMask: filter.extra?.maskPath)"
			[value]="formGroup.value[filter.slug]"
			matInput
		/>
	</mat-form-field>

	<!-- Toggle -->
	<ng-container *ngSwitchCase="'toggle'">
		<mat-slide-toggle [formControlName]="filter.slug">
			<div class="caption">
				<div class="text">
					<span class="name" title="{{ filter.name | entityFieldMask: filter.extra?.maskPath }}">{{
						filter.name | entityFieldMask: filter.extra?.maskPath
					}}</span>
				</div>
			</div>
		</mat-slide-toggle>
	</ng-container>
	<!-- Single Select -->
	<mat-form-field *ngSwitchCase="'single-select'" class="full-width" floatLabel="always">
		<mat-label>{{ filter.name | entityFieldMask: filter.extra?.maskPath }}</mat-label>
		<mat-select
			(valueChange)="emitUpdate()"
			(openedChange)="emitOpenedChange($event)"
			[compareWith]="compareWithId"
			[formControlName]="filter.slug"
			[placeholder]="filter.extra?.placeholder || 'Select an ' + (filter.name | entityFieldMask: filter.extra?.maskPath)"
		>
			<mat-option *ngFor="let entity of filteredOptions || filter.options" [value]="entity">{{
				entity.name || entity | titlecase
			}}</mat-option>
		</mat-select>
	</mat-form-field>

	<!-- Single Select with Groups -->
	<mat-form-field *ngSwitchCase="'single-select-with-groups'" class="full-width" floatLabel="always">
		<mat-label>{{ filter.name | entityFieldMask: filter.extra?.maskPath }}</mat-label>
		<mat-select (valueChange)="emitUpdate()" [compareWith]="compareWithId" [formControlName]="filter.slug">
			<mat-option>
				<ngx-mat-select-search
					[formControl]="filteredOptionsSearchCtrl"
					noEntriesFoundLabel="No matching options found"
					placeholderLabel="Search..."
				></ngx-mat-select-search>
			</mat-option>

			<mat-optgroup *ngFor="let group of filteredOptionsSubject | async" [label]="group.name" class="condensed-options">
				<mat-option *ngFor="let entity of group.items" [value]="entity">{{ entity.name }}</mat-option>
			</mat-optgroup>
		</mat-select>
	</mat-form-field>

	<!-- Single Select with Groups -->
	<mat-form-field *ngSwitchCase="'multi-select-with-search'" class="full-width multi-select-with-search" floatLabel="always">
		<mat-label>{{ filter.name | entityFieldMask: filter.extra?.maskPath }}</mat-label>
		<mat-select
			#select="matSelect"
			(closed)="onMultiSelectPanelClosed(select, filter)"
			[compareWith]="compareWithId"
			[disableOptionCentering]="true"
			[multiple]="true"
			[placeholder]="'Add ' + (filter.name | entityFieldMask: filter.extra?.maskPath)"
			[value]="formGroup?.get(filter?.slug)?.value"
			panelClass="multi-select-with-search"
			style="position: relative"
			(openedChange)="onMultiselectOpenedChange($event)"
		>
			<ng-container *localVar="(isMultiselectPanelOpen && !isMultiselectPanelLoading) || !select.panelOpen as showMultiselectOptions">
				<div [style.visibility]="showMultiselectOptions ? 'visible' : 'hidden'">
					<mat-option>
						<ngx-mat-select-search
							[formControl]="filteredOptionsSearchCtrl"
							noEntriesFoundLabel="No matching options found"
							placeholderLabel="Search..."
						></ngx-mat-select-search>
					</mat-option>
				</div>
				<mat-option disabled *ngIf="!showMultiselectOptions">
					<div class="deferred-select-placeholder-container">
						<mat-spinner class="spinner" diameter="30"></mat-spinner>
					</div>
				</mat-option>
				<div [style.visibility]="showMultiselectOptions ? 'visible' : 'hidden'">
					<mat-option *ngFor="let entity of (filteredOptions2Subject | async) ?? []" [value]="entity">{{
						entity.name
					}}</mat-option>
					<mat-option class="actions" disabled>
						<div class="flex justify-content-space-between align-items-center">
							<button (click)="onCloseMultiSelect(select)" color="tertiary" mat-button>Cancel</button>
							<button (click)="onApplyMultiSelect(select, filter)" class="button-extra-small" color="primary" mat-flat-button>
								Apply
							</button>
						</div>
					</mat-option>
				</div>
			</ng-container>
		</mat-select>
	</mat-form-field>

	<!-- Multi Select -->
	<app-autocomplete
		(added)="getSuggestedItems('')"
		(changed)="getSuggestedItems('')"
		(fullValueChange)="fullValueChanged($event, filter.slug)"
		(removed)="getSuggestedItems('')"
		(typing)="getSuggestedItems($event)"
		(valueChange)="update.emit(filter.slug)"
		*ngSwitchCase="'multi-select'"
		[addDescription]="filter.extra?.placeholder || 'Add ' + (filter.name | entityFieldMask: filter.extra?.maskPath)"
		[allItems]="filteredOptions || filter.options"
		[canAddNew]="filter.extra?.canAddNew"
		[controlName]="filter.slug"
		[filteredItems]="suggestedItems | orderBy: 'name'"
		[floatLabel]="'always'"
		[formGroup]="formGroup"
		[label]="filter.name | entityFieldMask: filter.extra?.maskPath"
	></app-autocomplete>

	<!-- Button Select -->
	<ng-container *ngSwitchCase="'button-select'">
		<app-button-select
			(applied)="patchValue(filter.slug, $event); update.emit($event)"
			(valueChanged)="patchValue(filter.slug, $event)"
			[applyButton]="filter.extra?.buttonSelect?.applyButton"
			[buttonColor]="filter.extra?.buttonSelect?.buttonColor"
			[buttonSize]="filter.extra?.buttonSelect?.buttonSize"
			[buttonText]="getButtonSelectText()"
			[classes]="filter.extra?.buttonSelect?.classes"
			[currentValue]="formGroup.value[filter.slug]"
			[iconName]="filter.extra?.buttonSelect?.iconName"
			[options]="filteredOptions || filter.options"
			[selectLayout]="filter.extra?.buttonSelect?.selectLayout || 'singles'"
			[showExpandButton]="filter.extra?.buttonSelect?.showExpandButton"
		></app-button-select>
	</ng-container>

	<!-- Toggle chip -->
	<ng-container *ngSwitchCase="'toggle-chip'">
		<p class="toggle-title mt-2">{{ filter?.name }}</p>
		<mat-button-toggle-group [formControlName]="filter.slug" class="mb-2">
			<mat-button-toggle *ngFor="let entity of filter.options" [value]="entity.value">{{ entity.name }}</mat-button-toggle>
		</mat-button-toggle-group>
	</ng-container>

	<!-- Toggle Multi Select -->
	<ng-container *ngSwitchCase="'toggle-multi-select'">
		<app-toggle-check-with-select
			[category]="filter.extra.category"
			[controlName]="filter.slug"
			[controlSelectlName]="filter.extra.controlSelectlName"
			[filter]="filter"
			[formGroup]="formGroup"
		>
		</app-toggle-check-with-select>
	</ng-container>

	<!-- Date -->
	<mat-form-field *ngSwitchCase="'date'" class="full-width filter-input-date" floatLabel="always">
		<mat-label>{{ filter?.name || "Date" }}</mat-label>
		<input (dateChange)="onDateChange($event, filter)" [formControlName]="filter.slug" [matDatepicker]="datePicker" matInput />
		<mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
		<mat-datepicker (opened)="cdkOverlayOpened.emit()" (closed)="cdkOverlayClosed.emit()" #datePicker></mat-datepicker>
	</mat-form-field>

	<!-- Date Range -->
	<ng-container *ngSwitchCase="'date-range'">
		<mat-form-field class="full-width" floatLabel="always">
			<mat-label>Date range</mat-label>
			<mat-date-range-input [rangePicker]="picker">
				<input [formControlName]="filter.extra?.prepend || '' + 'start'" matStartDate placeholder="Start" />
				<input [formControlName]="filter.extra?.prepend || '' + 'end'" matEndDate placeholder="End" />
			</mat-date-range-input>
			<mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
			<mat-date-range-picker #picker>
				<mat-date-range-picker-actions>
					<button mat-button matDateRangePickerCancel>Cancel</button>
					<button color="primary" mat-raised-button matDateRangePickerApply>Apply</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>

		<!-- Date Range Suggestions -->
		<div class="date-range-suggestions mb-4">
			<button
				(click)="setDateRangeFromPreset('full-period', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				Full Period
			</button>
			<button
				(click)="setDateRangeFromPreset('ytd', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				YTD
			</button>
			<button
				(click)="setDateRangeFromPreset('this-month', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				This Month
			</button>
			<button
				(click)="
					setDateRangeFromPreset('previous-month', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')
				"
				class="button-suggestion-small"
			>
				Previous Month
			</button>
			<br />
			<button
				(click)="setDateRangeFromPreset('this-quarter', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				This Quarter
			</button>
			<button
				(click)="
					setDateRangeFromPreset('previous-quarter', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')
				"
				class="button-suggestion-small"
			>
				Last Quarter
			</button>
			<button
				(click)="setDateRangeFromPreset('next-quarter', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				Next Quarter
			</button>
			<br />
			<button
				(click)="setDateRangeFromPreset('q1', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				Q1
			</button>
			<button
				(click)="setDateRangeFromPreset('q2', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				Q2
			</button>
			<button
				(click)="setDateRangeFromPreset('q3', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				Q3
			</button>
			<button
				(click)="setDateRangeFromPreset('q4', filter.extra?.prepend || '' + 'start', filter.extra?.prepend || '' + 'end')"
				class="button-suggestion-small"
			>
				Q4
			</button>
		</div>
	</ng-container>
</div>
