import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-empty-state',
	templateUrl: 'empty-state.component.html',
	styleUrls: ['empty-state.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateComponent implements OnInit {
	@Input() title: string;
	@Input() description?: string;

	@Input() actionTitle?: string;
	@Input() customActionButton?: boolean;

	@Output() actionClicked: EventEmitter<void> = new EventEmitter();

	constructor() {}

	ngOnInit(): void {}
}
