import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnDestroy,
	OnInit,
	Renderer2,
	ViewChild,
	ViewContainerRef,
	EventEmitter,
	Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Milestones } from '../../../../../state/entities/milestone/milestones.model';
import { ProgramMilestoneComponent } from '../../../../../state/entities/program/program-milestones/program-milestones.component';
import { Program } from './../../../../../state/entities/program/program.model';
import { MilestonesQuery } from '../../../../../state/entities/milestone/milestones.query';
import { map, startWith } from 'rxjs/operators';

@Component({
	selector: 'app-strategic-brief-dialog-milestones',
	templateUrl: 'milestones.component.html',
	styleUrls: ['milestones.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StrategicBriefDialogMilestonesComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() program: Program;
	@Input() viewOnly?: boolean;

	@Output() milestonesUpdated = new EventEmitter<Milestones[]>();

	milestones$: Observable<Milestones[]>;

	@ViewChild('dynamic', { read: ViewContainerRef }) private viewRef: ViewContainerRef;

	compRef: any;

	milestonesSubscription: Subscription;

	constructor(private readonly renderer: Renderer2, private readonly milestoneQuery: MilestonesQuery) {}

	ngOnInit(): void {
		this.milestonesSubscription = this.milestoneQuery
			.selectAll()
			.pipe(map((milestones) => milestones.filter((m) => m.created && m.programId === this.program.id)))
			.subscribe((res) => {
				this.milestonesUpdated.emit(res);
			});
	}

	ngAfterViewInit(): void {
		this.compRef = this.viewRef.createComponent(ProgramMilestoneComponent);
		this.compRef.instance.program = this.program;
		this.compRef.instance.viewOnly = this.viewOnly;
		this.compRef.changeDetectorRef.detectChanges();

		const html = this.compRef.location.nativeElement;

		const section = html.querySelector('section.white');
		this.renderer.setStyle(section, 'padding', '0');

		const h3 = html.querySelector('h3');
		this.renderer.setStyle(h3, 'display', 'none');

		setTimeout(() => {
			const container = html.querySelector('.entities-container');
			if (container && this.viewOnly) {
				this.renderer.setStyle(container, 'margin', '0');
			}
		}, 100);
	}

	ngOnDestroy(): void {
		this.compRef.destroy();
		this.milestonesSubscription.unsubscribe();
	}
}
