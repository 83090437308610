import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { FileService } from '../file.service';
import { GlobalService } from '../../../global/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EditorAnnotation, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { unfreeze } from '../../../../_core/utils/object.utils';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-file-pdf-viewer',
	templateUrl: './file-pdf-viewer.component.html',
	styleUrls: ['./file-pdf-viewer.component.scss'],
})
export class FilePdfViewerComponent implements OnChanges, OnInit, OnDestroy {
	@Input() file: PublicFile;
	@Input() annotationMode: boolean;
	@Input() annotations: EditorAnnotation[] = [];
	@Output() annotationsChanged: EventEmitter<EditorAnnotation[]> = new EventEmitter<EditorAnnotation[]>();

	public encodedUrl: Blob;
	public timer: any;
	public annotationLayerRendered: boolean = false;

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		private readonly fileService: FileService,
		private readonly globalService: GlobalService,
		private readonly pdfViewerService: NgxExtendedPdfViewerService
	) {}

	ngOnInit(): void {
		this.getTemporaryFileURL(this.file);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.annotations && this.annotationLayerRendered) {
			this.addAnnotations(changes.annotations.currentValue);
		}
	}

	ngOnDestroy(): void {
		clearInterval(this.timer);
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	getTemporaryFileURL(file: PublicFile): void {
		this.fileService
			.getTemporaryFileURL(file.path)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(response) => {
					this.encodedUrl = response.url;
				},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	onAnnotationLayerRendered(event: CustomEvent): void {
		this.annotationLayerRendered = true;

		if (this.timer) clearInterval(this.timer);

		// Listen for annotation changes and bubble them up to the parent component.
		this.timer = setInterval(() => {
			try {
				this.annotationsChanged.emit(this.pdfViewerService.getSerializedAnnotations());
				// console.log('Emitting annotations', this.pdfViewerService.getSerializedAnnotations()[0]?.rect);
			} catch (error) {
				this.globalService.triggerErrorMessage(error);
			}
		}, 500);
	}

	addAnnotations(annotations: EditorAnnotation[]): void {
		if (this.annotations?.length > 0) {
			// Compare the annotations to the current annotations and only add new ones.
			const currentAnnotations = this.pdfViewerService.getSerializedAnnotations();
			try {
				if (JSON.stringify(currentAnnotations) === JSON.stringify(annotations)) {
					console.log('Annotations are the same. Skipping.');
					return;
				}
			} catch (e) {
				console.log('Couldnt parse annotations');
			}

			this.pdfViewerService.removeEditorAnnotations();

			for (const annotation of this.annotations) {
				this.pdfViewerService.addEditorAnnotation(unfreeze(annotation));
			}

			console.log('Scrolling to page', this.annotations[0].pageIndex);
			this.pdfViewerService.scrollPageIntoView(this.annotations[0].pageIndex + 1, { top: 0, left: 0 });
		} else {
			this.pdfViewerService.removeEditorAnnotations();
		}
	}
}
