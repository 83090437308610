<div class="section-group" fxFlexFill fxLayout="row" fxLayoutGap="30px">
	<section class="white content-row" fxFlex="100%" fxLayout="column">
		<div class="title-row">
			<div class="group left">
				<h3 class="title">Milestones</h3>
			</div>
			<div
				*hasPermissionRequirements="[
					{
						minLevel: PermissionLevel.Write
					}
				]"
				class="group right"
			>
				<button
					(click)="createBlank()"
					*ngIf="(milestones$ | async)?.length > 0 && !viewOnly"
					class="button-extra-small icon-right"
					mat-flat-button
				>
					Add Milestone<i class="icon icon-add"></i>
				</button>
			</div>
		</div>

		<div *ngIf="(milestones$ | async)?.length < 1" class="get-started-container">
			<div class="content-container">
				<ng-container *hasPermissionRequirements="{ minLevel: PermissionLevel.Write }; else noPermission">
					<h2 class="title">
						{{
							!viewOnly ? "Add milestones to track of important dates for your entity." : "No milestones have been added yet."
						}}
					</h2>
					<p class="description" *ngIf="!viewOnly">Get started below.</p>

					<div class="actions" *ngIf="!viewOnly">
						<button (click)="createBlank()" class="button-extra-small" color="primary" mat-flat-button>Add Milestones</button>
					</div>
				</ng-container>
				<ng-template #noPermission>
					<h2 class="title">No milestones have been added yet.</h2>
				</ng-template>
			</div>
		</div>

		<app-milestone-list
			(clicked)="openMilestoneDialog($event)"
			(created)="create($event)"
			(removed)="remove($event)"
			*ngIf="(milestones$ | async)?.length > 0"
			[milestones]="milestones$ | async"
			[program]="program"
			[tactic]="program.tactics"
			[viewOnly]="viewOnly"
		></app-milestone-list>
	</section>
</div>
