import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Filter } from '../../../state/entities/filter/filter.model';
import { FilterService } from '../../../state/entities/filter/filter.service';

@Component({
	selector: 'app-toggle-check-with-select',
	templateUrl: './toggle-check-with-select.component.html',
	styleUrls: ['./toggle-check-with-select.component.scss'],
})
export class ToggleCheckWithSelectComponent implements OnInit {
	@Input() filter: Filter;
	@Input() formGroup: FormGroup;
	@Input() controlName: string;
	@Input() controlSelectlName: string;
	@Input() category: string;
	@Output() update: EventEmitter<string> = new EventEmitter();

	dropdownList = [];
	selectedItems = [];
	dropdownSettings = {};
	showSelect: boolean = false;

	private readonly _unsubscribe$: Subject<void> = new Subject();

	constructor(private filterService: FilterService) {}

	ngOnInit(): void {
		this.init();
		if (this.formGroup) {
			// Emit value changes to parent
			// Hack: Had to add a timeout, because this was firing before the value was really set?
			// So the api call was a step behind from the state.
			this.formGroup
				.get(this.controlName)
				.valueChanges.pipe(takeUntil(this._unsubscribe$))
				.subscribe((value) => setTimeout(() => this.update.emit(value), 100));

			this.formGroup
				.get(this.controlSelectlName)
				.valueChanges.pipe(takeUntil(this._unsubscribe$))
				.subscribe((value) => setTimeout(() => this.update.emit(value?.itemName), 100));
		}
	}

	showMultiSelect(value: number): void {
		this.selectedItems = [];
		if (value) {
			this.showSelect = true;
			return;
		}
		this.showSelect = false;
	}

	init(): void {
		this.dropdownList = this.filterService.getFilterCategory(this.category);

		this.dropdownList = this.dropdownList.map((option) => (option.itemName ? option.name : { ...option, itemName: option.name }));

		this.dropdownSettings = {
			singleSelection: false,
			text: `Types`,
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			enableSearchFilter: true,
			classes: 'myclass custom-class',
		};
	}

	onItemSelect(item: any): void {
		console.log(item);
		console.log(this.selectedItems);
	}

	OnItemDeSelect(item: any): void {
		console.log(item);
		console.log(this.selectedItems);
	}

	onSelectAll(items: any): void {
		console.log(items);
	}

	onDeSelectAll(items: any): void {
		console.log(items);
	}
}
