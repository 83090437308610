import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Entities } from '../../global/global.model';
import { TableCollection } from '../../table/table.model';
import { Plan } from '../plan/plan.model';
import { Program } from '../program/program.model';
import { EditFileDialog, FileCategory, FileState, FileUploadRequest, GetReviewFilesDto } from './file.model';
import { FileStore } from './file.store';
import { UpdateFileDto } from '../../../../../../api/src/file/dtos/update-file.dto';
import { AddFileViewersDto } from '../../../../../../api/src/file/dtos/add-file-viewers.dto';
import { RemoveFileViewersDto } from '../../../../../../api/src/file/dtos/remove-file-viewers.dto';

import * as FileSaver from 'file-saver';
import { ReviewerRole } from './file-reviewers-dialog/file-reviewers-dialog.component';
import { PublicFile } from '../../../../../../api/src/file/file.entity';
import { AddCommentDto } from '../../../../../../api/src/comment/dtos/add-comment.dto';
import { arrayUpsert } from '@datorama/akita';
import { PublicComment } from '../../../../../../api/src/comment/comment.entity';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Observable } from 'rxjs';
import { User } from '../user/user.model';
import { formatDate } from '@angular/common';

/**
 * File Service
 * This service handles all file related logic and API calls.
 */
@Injectable({ providedIn: 'root' })
export class FileService {
	constructor(readonly fileStore: FileStore, readonly http: HttpClient) {}

	/**
	 * Get a list of file items across all entities using filterParameters
	 * Used by the file explorer or anywhere we want to search across the whole org for files.
	 */
	find(filters: FileState, append = false) {
		const params = this.getParams(filters);

		console.log('Filters File', filters);

		const body: any = {
			category: filters.category.id,
			sort: filters.sort,
			sortStrategy: filters.sortStrategy,
			programFacets: filters.programFacets,
		};
		if (filters?.planFacets) {
			body.planFacets = filters.planFacets;
			delete body.programFacets;
		}
		if (filters?.tacticFacets) {
			body.tacticFacets = filters.tacticFacets;
			delete body.programFacets;
		}

		return this.http
			.post<TableCollection<any>>(`${environment.apiUrl}/organization/${environment.organizationId}/file`, body, { params })
			.pipe(
				tap((resp) => {
					if (append) {
						this.fileStore.upsertMany(resp.items);
					} else {
						this.fileStore.set(resp.items);
					}

					// Reset the page unless we're appending items
					const obj: Partial<FileState> = {
						totalResults: resp.totalResults,
					};
					if (!append) {
						obj.page = 1;
					}

					this.fileStore.update(obj);
				})
			);
	}

	/**
	 * Find more files with the inputed filters.  Will append any found items to the current Akita store.
	 */
	findMore(filters: FileState) {
		return this.find(filters, true);
	}

	/**
	 * Get a list of file items for single entities
	 */
	get(filters: FileState, append = false) {
		const params = this.getParams(filters);

		return this.http
			.get<TableCollection<any>>(`${environment.apiUrl}/organization/${environment.organizationId}/file`, {
				params,
			})
			.pipe(
				tap((resp) => {
					if (append) {
						this.fileStore.upsertMany(resp.items);
					} else {
						this.fileStore.set(resp.items);
					}

					// Reset the page unless we're appending items
					const obj: Partial<FileState> = {
						totalResults: resp.totalResults,
					};
					if (!append) {
						obj.page = 1;
					}

					this.fileStore.update(obj);
				})
			);
	}

	getReviewFiles(dto: Partial<GetReviewFilesDto>, append = false, updateStore = true) {
		this.fileStore.setLoading(true);
		return this.http
			.get<TableCollection<any>>(`${environment.apiUrl}/organization/${environment.organizationId}/file/review`, {
				params: this.getParams(dto as FileState),
			})
			.pipe(
				tap((resp) => {
					if (updateStore) {
						if (append) {
							this.fileStore.upsertMany(resp.items);
						} else {
							this.fileStore.set(resp.items);
						}

						// Reset the page unless we're appending items
						const obj: Partial<FileState> = {
							totalResults: resp.totalResults,
						};
						if (!append) {
							obj.page = 1;
						}

						this.fileStore.update(obj);
					}
				})
			);
	}

	addFileComment(file: PublicFile, comment: AddCommentDto) {
		return this.http
			.post<PublicComment>(`${environment.apiUrl}/organization/${environment.organizationId}/file/${file.id}/comment`, comment)
			.pipe(
				tap((comment) => {
					this.fileStore.update(file.id, {
						comments: arrayUpsert(file.comments || [], comment.id, comment),
					});
				})
			);
	}

	updateFileCommentsState(file, comment) {
		this.fileStore.update(file.id, {
			comments: arrayUpsert(file.comments || [], comment.id, comment),
		});

		this.fileStore.update((state) => {
			// clone the state
			const newState = Object.assign({}, state);

			// Create a new entities object with updated versions
			const updatedEntities = Object.entries(newState.entities).reduce((updatedEntities, [entityId, entity]) => {
				let updatedVersions = entity.versions;

				if (entity.versions) {
					updatedVersions = entity.versions.map((fileVersion) => {
						if (fileVersion.id === file.id) {
							return {
								...fileVersion,
								comments: arrayUpsert(fileVersion.comments || [], comment.id, comment),
							};
						}
						return fileVersion;
					});
				}
				// Always return the entity, with updated versions if they exist
				updatedEntities[entityId] = {
					...entity,
					versions: updatedVersions,
				};

				return updatedEntities;
			}, {});

			// Return the new state with updated entities
			return {
				...newState,
				entities: updatedEntities,
			};
		});
	}

	getTemporaryFileURL(fileName: string) {
		return this.http.get<any>(`${environment.apiUrl}${fileName}/presigned`).pipe(tap((resp) => {}));
	}

	/**
	 * Get more files for a single entity.  Will append any found items to the current Akita store.
	 */
	getMore(filters: FileState) {
		return this.get(filters, true);
	}

	/**
	 * Set the current filers in the Akita store.
	 */
	set(files: PublicFile[]) {
		this.fileStore.set(files);
	}

	/**
	 * Create a new file item on the API.  Uploads the file data to the API as well, and returns progress events.
	 */
	upload(request: FileUploadRequest, id: Program['id'] | Plan['id'], entityType: Entities) {
		const formData: FormData = new FormData();

		formData.append('file', request.data);
		formData.append('name', request.name);

		let type: string = entityType;

		if (request.category) {
			formData.append('category', request.category.id);
		}

		if (request.tactic) {
			formData.append('tacticId', request.tactic.id);
		}

		if (request.program) {
			formData.append('programId', request.program.id);
		}

		if (type === 'budgetPeriod') {
			type = 'budget-period';
		}

		if (request.requiresApproval != null) {
			formData.append('requiresApproval', request.requiresApproval.toString());
		}

		const req = new HttpRequest(
			'POST',
			`${environment.apiUrl}/organization/${environment.organizationId}/${type}/${id}/file`,
			formData,
			{
				reportProgress: true,
				responseType: 'json',
			}
		);

		return this.http.request(req);
	}

	/**
	 * Update a file item's metadata on the API.
	 * You can't change the file data from this endpoint.
	 */
	update(file: EditFileDialog, data: UpdateFileDto, uploadedFile?: FileUploadRequest) {
		this.fileStore.setLoading(true);
		const basePath = `${environment.apiUrl}`;
		const optionalPath = file.data.path.split('.').slice(0, -1).join('.');

		const formData: FormData = new FormData();

		if (uploadedFile?.data) {
			formData.append('file', uploadedFile.data);
		}
		if (data) {
			formData.append('data', JSON.stringify(data));
		}

		return this.http.put<PublicFile>(`${basePath}${optionalPath}`, formData).pipe(
			tap((file) => {
				if (file.tactic === undefined) {
					file.tactic = null;
				}
				this.fileStore.upsert(file.id, file);
				this.fileStore.setLoading(false);
			})
		);
	}

	/**
	 * Attempt to download a secure file from the API.
	 */
	download(path: string, saveAs = true, fileName?: string) {
		const filename = !fileName ? path.replace(/^.*[\\\/]/, '') : fileName;

		return this.http
			.get(`${environment.apiUrl}${path}?download=true`, {
				responseType: 'blob',
			})
			.pipe(
				tap((resp) => {
					if (saveAs) {
						FileSaver.saveAs(resp, filename);
					}
				})
			);
	}

	/**
	 * Add a file item to the Akita store.  Automatically prepends the item to the list.
	 */
	add(file: PublicFile) {
		this.fileStore.add(file, { prepend: true });
	}

	/**
	 * Remove a file item from the API.
	 */
	remove(file: PublicFile) {
		return this.http.delete(`${environment.apiUrl}${file.path}`).pipe(tap(() => this.fileStore.remove(file.id)));
	}

	addToReview(file: PublicFile) {
		this.fileStore.setLoading(true);

		const params: UpdateFileDto = {
			requiresApproval: true,
		};
		const path = file.path.split('.').slice(0, -1).join('.');

		const formData: FormData = new FormData();

		formData.append('data', JSON.stringify(params));

		return this.http.put<PublicFile>(`${environment.apiUrl}${path}`, formData).pipe(
			tap((file) => {
				this.fileStore.upsert(file.id, file);
				this.fileStore.setLoading(false);
			})
		);
	}

	removeFromReview(file: PublicFile) {
		this.fileStore.setLoading(true);

		const params: UpdateFileDto = {
			requiresApproval: false,
		};
		const path = file.path.split('.').slice(0, -1).join('.');

		const formData: FormData = new FormData();

		formData.append('data', JSON.stringify(params));

		return this.http.put<PublicFile>(`${environment.apiUrl}${path}`, formData).pipe(
			tap((file) => {
				this.fileStore.upsert(file.id, file);
				this.fileStore.setLoading(false);
			})
		);
	}

	addReviewers(
		file: PublicFile,
		emails: string[],
		role: ReviewerRole,
		message?: string,
		feedbackDueDate?: string,
		sendNotification: boolean = true
	) {
		this.fileStore.setLoading(true);
		const params: AddFileViewersDto = {
			[role + 's']: {
				emails,
			},
			message,
			feedbackDueDate: feedbackDueDate ? formatDate(feedbackDueDate, 'yyyy-MM-dd', 'en-US') : undefined,
			sendNotification,
		};

		return this.http
			.put<PublicFile>(`${environment.apiUrl}/organization/${environment.organizationId}/file/${file.id}/review/viewers`, params)
			.pipe(
				tap((file) => {
					this.fileStore.upsert(file.id, file);
					this.fileStore.setLoading(false);
				})
			);
	}

	addReviewersBatch(
		file: PublicFile,
		approvers: User[] = [],
		reviewers: User[] = [],
		message?: string,
		feedbackDueDate?: string,
		sendNotification: boolean = true
	) {
		this.fileStore.setLoading(true);
		const dto: AddFileViewersDto = {
			approvers: {
				emails: approvers.map((val) => val.email),
			},
			reviewers: {
				emails: reviewers.map((val) => val.email),
			},
			feedbackDueDate: feedbackDueDate ? formatDate(feedbackDueDate, 'yyyy-MM-dd', 'en-US') : undefined,
			message,
			sendNotification,
		};

		return this.http
			.put<PublicFile>(`${environment.apiUrl}/organization/${environment.organizationId}/file/${file.id}/review/viewers`, dto)
			.pipe(
				tap((file) => {
					this.fileStore.upsert(file.id, file);
					this.fileStore.setLoading(false);
				})
			);
	}

	removeReviewers(file: PublicFile, ids: string[], role: ReviewerRole) {
		this.fileStore.setLoading(true);

		const params: RemoveFileViewersDto = {
			[role + 's']: ids,
		};

		return this.http
			.delete<PublicFile>(`${environment.apiUrl}/organization/${environment.organizationId}/file/${file.id}/review/viewers`, {
				body: params,
			})
			.pipe(
				tap((file) => {
					this.fileStore.upsert(file.id, file);
					this.fileStore.setLoading(false);
				})
			);
	}

	/**
	 * Updates the current filters in the Akita store.
	 */
	updateFilters(params: Partial<FileState>) {
		this.fileStore.update(params);
	}

	/**
	 * Set the category filter in the Akita store.
	 */
	setCategory(category: FileCategory) {
		this.fileStore.update({
			category,
		});
	}

	setTacticType(tacticTypeId: string) {
		this.fileStore.update({
			tacticTypeId,
		});
	}

	/**
	 * Increment the page property in the Akita store.
	 */
	nextPage() {
		this.fileStore.update({
			page: this.fileStore.getValue().page + 1,
		});
	}

	/**
	 * Reset all of the Akita filters to their default values.
	 */
	resetFilters() {
		this.fileStore.resetFilters();
	}

	/**
	 * Reset the pagination filters	in the Akita store.
	 */
	resetPageFilters() {
		this.fileStore.resetPageFilters();
	}

	/**
	 * Extract the current filters into HttpParams for API calls.
	 */
	getParams(filters: FileState) {
		let params: HttpParams = new HttpParams();

		if (filters.category && filters.category.id) {
			params = params.set('category', filters.category.id);
		}
		if (filters.tacticTypeId) {
			params = params.set('tacticTypeId', filters.tacticTypeId);
		}
		if (filters.page) {
			params = params.set('page', filters.page?.toString());
		}
		if (filters.limit) {
			params = params.set('perPage', filters.limit?.toString());
		}
		if (filters.programId) {
			if (Array.isArray(filters.programId)) {
				filters.programId.forEach((id) => {
					params = params.append('programId', id);
				});
			} else {
				params = params.set('programId', filters.programId);
			}
		}
		if (filters.planId) {
			params = params.set('planId', filters.planId);
		}
		if (filters.budgetPeriodId) {
			params = params.set('budgetPeriodId', filters.budgetPeriodId);
		}
		if (filters.tacticId) {
			params = params.set('tacticId', filters.tacticId);
		}
		if (filters.sort) {
			params = params.set('sort', filters.sort);
		}
		if (filters.sortStrategy) {
			params = params.set('sortStrategy', filters.sortStrategy);
		}
		if (typeof filters.approved !== 'undefined') {
			params = params.set('approved', filters.approved);
		}
		if (filters.approvedByMe) {
			params = params.set('approvedByMe', filters.approvedByMe);
		}
		if (filters.needsMyApproval) {
			params = params.set('needsMyApproval', filters.needsMyApproval);
		}
		if (filters.brandIds?.length) {
			params = params.set('brandIds', filters.brandIds);
		}
		if (filters.retailerIds?.length) {
			params = params.set('retailerIds', filters.retailerIds);
		}
		if (filters.reviewStatus) {
			params = params.set('reviewStatus', filters.reviewStatus);
		}

		return params;
	}

	/**
	 * Test a mime type string to see if it's a valid image
	 */
	isImage(mimeType: string) {
		const imageMimeTypes = ['image/jpeg', 'image/png'];
		return imageMimeTypes.indexOf(mimeType) > -1;
	}

	/**
	 * Test a mime type string to see if it's a valid video
	 */
	isVideo(mimeType: string) {
		return mimeType?.indexOf('video') > -1;
	}

	/**
	 * Get proper viewer to use
	 * https://stackoverflow.com/questions/4212861/what-is-a-correct-mime-type-for-docx-pptx-etc
	 */
	viewerToUse(mimeType: string) {
		if (!mimeType) {
			return 'microsoft';
		}
		if (mimeType === 'application/pdf') {
			return 'pdf';
		}

		if (mimeType.indexOf('word') > -1) {
			return 'microsoft';
		}

		if (mimeType.indexOf('spreadsheet') > -1 || mimeType.indexOf('excel') > -1) {
			return 'microsoft';
		}

		if (mimeType.indexOf('powerpoint') > -1 || mimeType.indexOf('presentation') > -1) {
			return 'microsoft';
		}

		return 'microsoft';
	}

	/**
	 * Retrieve an icon string based on the mimeType of the file.
	 */
	getFileTypeIcon(mimeType: string) {
		if (!mimeType) {
			return 'uil-file-alt';
		}
		if (mimeType === 'application/pdf') {
			return 'icon icon-pdf';
		}

		if (mimeType.indexOf('word') > -1) {
			return 'icon icon-word';
		}

		if (mimeType.indexOf('spreadsheet') > -1 || mimeType.indexOf('excel') > -1) {
			return 'icon icon-excel';
		}

		if (mimeType.indexOf('powerpoint') > -1 || mimeType.indexOf('presentation') > -1) {
			return 'icon icon-powerpoint';
		}

		if (mimeType.indexOf('video') > -1) {
			return 'icon icon-video';
		}

		if (mimeType.indexOf('image') > -1) {
			return 'icon icon-image';
		}

		return 'uil-file-alt';
	}

	/**
	 * Retrieve a base 64 image from URL.
	 */
	async getBase64ImageFromURL(url) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.setAttribute('crossOrigin', 'anonymous');

			img.onload = () => {
				const canvas = document.createElement('canvas');
				canvas.width = img.width;
				canvas.height = img.height;

				const ctx = canvas.getContext('2d');
				ctx.drawImage(img, 0, 0);

				const dataURL = canvas.toDataURL('image/png');

				resolve(dataURL);
			};

			img.onerror = (error) => {
				reject(error);
			};

			img.src = url;
		});
	}

	/**
	 *  Generate PDF file from HTML
	 */
	generatePdf(content: HTMLElement, name: string = 'myDocument', landscape: boolean = false): Observable<File> {
		return new Observable<File>((observer) => {
			html2canvas(content, { scrollY: -window.scrollY })
				.then((canvas) => {
					const pdf = new jsPDF({
						orientation: landscape ? 'landscape' : 'portrait',
					});

					const imgData = canvas.toDataURL('image/png');
					const pdfWidth = pdf.internal.pageSize.getWidth();
					const pdfHeight = pdf.internal.pageSize.getHeight();
					const imgWidth = canvas.width;
					const imgHeight = canvas.height;

					// Scale the image to fit the PDF width
					const scaleFactor = pdfWidth / imgWidth;
					const scaledHeight = imgHeight * scaleFactor;

					let y = 0;

					// Generate pages as long as there is content
					while (y < scaledHeight) {
						const sliceCanvas = document.createElement('canvas');
						sliceCanvas.width = canvas.width;
						sliceCanvas.height = pdfHeight / scaleFactor;

						const sliceContext = sliceCanvas.getContext('2d');
						sliceContext?.drawImage(
							canvas,
							0,
							y / scaleFactor,
							canvas.width,
							pdfHeight / scaleFactor,
							0,
							0,
							canvas.width,
							pdfHeight / scaleFactor
						);

						const sliceImgData = sliceCanvas.toDataURL('image/png');
						pdf.addImage(sliceImgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');

						if (y + pdfHeight < scaledHeight) {
							pdf.addPage();
						}
						y += pdfHeight;
					}

					const blob = pdf.output('blob');
					const file = new File([blob], `${name}.pdf`, { type: 'application/pdf', lastModified: Date.now() });
					observer.next(file);
					observer.complete();
				})
				.catch((err) => {
					observer.error(err);
				});
		});
	}

	sendMailForComment(commentId: string, taggedUserIds?: User[]) {
		let params: HttpParams = new HttpParams();
		if (taggedUserIds?.length) {
			params = params.set('taggedUserIds', taggedUserIds.map((val) => val.id).join(','));
		}
		return this.http.post(`${environment.apiUrl}/organization/${environment.organizationId}/comment/${commentId}/sendMail`, null, {
			params,
		});
	}
}
