<div class="section-group mb-5">
	<section
		class="white stretch font-size-medium"
		fxLayout="column"
		*localVar="program?.programStrategicBrief?.deliverable as deliverable"
	>
		<div class="title-row">
			<div class="group left">
				<h3 class="title">{{ briefTitle(deliverable) }}</h3>
			</div>
			<div class="group right" *ngIf="!exportView && supportedBriefs.includes(deliverable.slug)">
				<button mat-flat-button class="button-extra-small icon-left" (click)="updateClicked.emit()">
					<i class="uil-document-info"></i> Update brief
				</button>
			</div>
		</div>

		<ng-container *ngIf="supportedBriefs.includes(deliverable.slug); else noSupport">
			<app-strategic-brief-view-default
				[program]="program"
				[exportView]="exportView"
				(dataSubscribed)="dataSubscribed.emit()"
			></app-strategic-brief-view-default>
		</ng-container>

		<ng-template #noSupport>
			<app-empty-state
				class="full-width"
				title="Brief type not supported"
				description="A brief cannot be viewed for the selected brief type. Please contact your administrator for more details."
			></app-empty-state>
		</ng-template>
	</section>
</div>
