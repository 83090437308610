import { Column, Entity, Index, JoinColumn, ManyToOne, PrimaryGeneratedColumn } from 'typeorm';
import { Organization } from '../organization/organization.entity';

@Entity('deliverables')
@Index(['name'])
export class DeliverableEntity {
	@PrimaryGeneratedColumn('uuid', { name: 'id' }) id: string;
	@Column({ name: 'name', type: 'text', nullable: false }) name: string;
	@Column({ name: 'slug', type: 'text', nullable: true }) slug: string;
	@Column({ name: 'label', type: 'text', nullable: true }) label: string;
	@Column('boolean', { default: false, nullable: false }) deleted: boolean;
	@Column('boolean', { default: false, nullable: false }) enabled: boolean;

	@ManyToOne(() => Organization, { eager: false, nullable: true, onDelete: 'CASCADE' })
	@JoinColumn({ name: 'organization_id' })
	organization: string | Organization;
}
