import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Program } from './../../../state/entities/program/program.model';
import { Deliverable } from '../../../state/entities/deliverable/models/deliverable.model';
import {
	strategicBriefSupportedBriefTypes,
	StrategicBriefSupportedType,
	strategyBriefTitleByDeliverable,
} from '../strategic-brief-dialog/types/default/strategic-brief-default.data';

@Component({
	selector: 'app-strategic-brief-view',
	templateUrl: 'strategic-brief-view.component.html',
	styleUrls: ['strategic-brief-view.component.scss'],
})
export class StrategicBriefViewComponent implements OnInit {
	@Input() program: Program;
	@Input() exportView?: boolean;
	@Input() pageView?: boolean;
	@Output() updateClicked = new EventEmitter<void>();
	@Output() dataSubscribed = new EventEmitter<void>();

	briefTitle = strategyBriefTitleByDeliverable;

	supportedBriefs: StrategicBriefSupportedType[] = strategicBriefSupportedBriefTypes;

	constructor() {}

	ngOnInit(): void {}
}
