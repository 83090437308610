import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PublicFile, PublicFileVersion } from '../../../../../../../api/src/file/file.entity';
import { GlobalService } from '../../../global/global.service';
import { FileService } from '../file.service';
import { FileVersionUploadDialogComponent } from '../file-version-upload-dialog/file-version-upload-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { FileReviewersDialogComponent } from '../file-reviewers-dialog/file-reviewers-dialog.component';
import { PublicComment } from '../../../../../../../api/src/comment/comment.entity';
import { CsvUtils } from '../../../../_core/utils/csv.utils';
import { Router } from '@angular/router';
import { SessionQuery } from '../../../../state/session/session.query';
import { first, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-file-review',
	templateUrl: './file-review.component.html',
	styleUrls: ['./file-review.component.scss'],
})
export class FileReviewComponent implements OnInit, OnChanges, OnDestroy {
	@Input() file: PublicFile;
	@Input() fileVersionId: string;
	@Input() showProgram: boolean = true;
	@Input() showGoToProgram: boolean = false;
	@Input() showTactic: boolean = true;
	@Input() showCloseButton: boolean = false;

	@Output() selectedFileVersion = new EventEmitter<PublicFile>();
	@Output() closeParent: EventEmitter<void> = new EventEmitter<void>();

	public annotations: any[] = [];
	public annotationMode: boolean = false;
	public activeComment: PublicComment;
	public isGuestUser: boolean = false;

	fileVersion: PublicFile;

	private readonly _unsubscribe$: Subject<void> = new Subject();

	constructor(
		private readonly fileService: FileService,
		private readonly globalService: GlobalService,
		private readonly dialog: MatDialog,
		private readonly router: Router,
		private readonly sessionQuery: SessionQuery
	) {}

	ngOnInit(): void {
		this.isGuestUser = this.sessionQuery.getRole() === 'guest';
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.file.versions) {
			this.fileVersion = this.file.versions.find((version) => version.id === this.fileVersionId) as PublicFile;
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	download(): void {
		// Check and download file version if it exists.
		if (this.fileVersion) {
			this.fileService
				.download(this.fileVersion.path, true, this.fileVersion.name)
				.pipe(first(), takeUntil(this._unsubscribe$))
				.subscribe(
					() => {},
					(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
				);
		} else {
			this.fileService
				.download(this.file.path, true, this.file.name)
				.pipe(first(), takeUntil(this._unsubscribe$))
				.subscribe(
					() => {},
					(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
				);
		}
	}

	copyLink(): void {
		// Build the direct link to the file.
		const link = window.location.origin + '/file-review/' + this.file.program.id + '/' + this.file.id;
		navigator.clipboard.writeText(link);
		this.globalService.triggerSaveMessage('Link copied to clipboard');
	}

	onSelectFileVersion(fileVersion: PublicFileVersion): void {
		this.fileVersion = fileVersion as PublicFile;
		this.selectedFileVersion.emit(this.fileVersion);
	}

	onOpenFileVersionUploadDialog(): void {
		this.dialog.open(FileVersionUploadDialogComponent, {
			data: { file: this.file },
			disableClose: true,
			panelClass: ['fullscreen', 'max-width-lg'],
		});
	}

	reviewersModal(): void {
		const dialogRef = this.dialog.open(FileReviewersDialogComponent, {
			data: { file: this.file },
			panelClass: ['fullscreen', 'background-color', 'max-width-sm'],
			disableClose: false,
		});

		dialogRef.afterClosed().pipe(takeUntil(this._unsubscribe$)).subscribe();
	}

	goToProgram(): void {
		this.router.navigate(['/program', this.file.program.id]);
		this.closeParent.emit();
	}

	onCommentStateChange(event: string): void {
		if (event === 'add') {
			this.annotationMode = true;
		} else {
			this.annotationMode = false;
		}
		this.activeComment = undefined;
		this.annotations = [];
	}

	onAnnotationsChanged(annotations: any[]): void {
		if (this.annotationMode) {
			this.annotations = annotations;
		}
	}

	async onDownloadCommentsCsv(): Promise<void> {
		let fileVersion = this.file;

		const foundVersionId = this.fileVersionId;
		if (foundVersionId) {
			const foundFileVersionIndex = this.file.versions.findIndex((version) => version.id === foundVersionId);
			if (this.file.versions[foundFileVersionIndex]) {
				// Replace file with chosen version & add version number to file
				fileVersion = {
					...(this.file.versions[foundFileVersionIndex] as PublicFile),
					versionNumber: this.file.versions.length + 1 - foundFileVersionIndex,
				} as PublicFile & { versionNumber: number };
			}
		}

		const csvData: any[] = [];
		(fileVersion.comments ?? []).forEach((comment) => {
			const csvEntry: any = {};

			csvEntry.fileName = fileVersion.name;
			csvEntry.commentText = comment.body;
			csvEntry.commentId = comment.id;
			csvEntry.timestamp = comment.created;
			csvEntry.author = (comment?.author?.nameFirst ?? '') + ' ' + (comment?.author?.nameLast ?? '');
			csvEntry.authorEmail = comment?.author?.email ?? '';
			csvEntry.status = comment.status ?? 'n/a';
			csvEntry.annotations = comment.annotations?.length ? 'Yes' : 'No';
			csvEntry.versionNumber = fileVersion?.['versionNumber'] ?? 1;
			csvEntry.assignedTo = fileVersion?.approvers?.length
				? fileVersion.approvers[0]?.nameFirst + ' ' + fileVersion.approvers[0]?.nameLast
				: 'n/a';
			csvData.push(csvEntry);
		});
		if (!csvData || csvData?.length === 0) {
			this.globalService.triggerWarningMessage('No comments to export.');
			return;
		}

		await CsvUtils.generateCsvAndDownload(`csv-comments-export-${new Date().toISOString()}`, csvData);
	}
}
