import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PublicFile } from '../../../../../../../api/src/file/file.entity';
import { GlobalService } from '../../../global/global.service';
import { User } from '../../user/user.model';
import { FileService } from '../file.service';
import { Subject } from 'rxjs';
import { SessionStore } from '../../../session/session.store';
import { Profile } from '../../../session/session.model';
import { first, takeUntil } from 'rxjs/operators';

export interface FileReviewersDialogData {
	file: PublicFile;
	filePendingReviewers?: FilePendingReviewersResult[];
	previewOnly?: boolean;
	isInitialReview?: boolean;
	autoAddSelfAsReviewer?: boolean;
}
export enum FilePendingReviewerType {
	CREATE = 'CREATE',
	REMOVE = 'REMOVE',
}
export interface FilePendingReviewersResult {
	type: FilePendingReviewerType;
	reviewers?: User[];
	userId?: string;
	role?: ReviewerRole;
	message?: string;
	feedbackDueDate?: string;
}

export enum ReviewerRole {
	Reviewer = 'reviewer',
	Approver = 'approver',
}

@Component({
	selector: 'app-file-reviewers-dialog',
	templateUrl: './file-reviewers-dialog.component.html',
	styleUrls: ['./file-reviewers-dialog.component.scss'],
})
export class FileReviewersDialogComponent implements OnInit, OnDestroy {
	public form: FormGroup;
	public reviewersFilter = {
		id: 'reviewers',
		name: 'Add Reviewers',
		category: 'reviewers',
		slug: 'reviewers',
		type: 'multi-select',
		extra: {
			suggestEntity: 'reviewers',
			canAddNew: true,
			placeholder: 'Enter name or email',
		},
	};
	public reviewerRoleLabels = [
		{
			value: ReviewerRole.Approver,
			name: 'Require Approval',
		},
		{
			value: ReviewerRole.Reviewer,
			name: 'Review Only',
		},
	];
	public ReviewerRole = ReviewerRole;

	filePendingReviewers: FilePendingReviewersResult[] = [];
	minDate = new Date();

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<FileReviewersDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: FileReviewersDialogData,
		public readonly fileService: FileService,
		public readonly globalService: GlobalService,
		public readonly sessionStore: SessionStore
	) {
		if (this.data.filePendingReviewers?.length) {
			this.filePendingReviewers = this.data.filePendingReviewers;
			// Removes already removed reviewers
			this.data.filePendingReviewers
				.filter((val) => val.type === FilePendingReviewerType.REMOVE)
				.forEach((val) => {
					this.removeReviewer({ id: val.userId } as User, val.role);
				});
		}
	}

	private _listenForFormChanges(): void {
		this.form
			.get('feedbackDueDate')
			?.valueChanges.pipe(takeUntil(this._unsubscribe$))
			.subscribe((feedbackDueDate) => {
				this.filePendingReviewers = this.filePendingReviewers.map((val) => ({ ...val, feedbackDueDate }));
			});

		this.form
			.get('message')
			?.valueChanges.pipe(takeUntil(this._unsubscribe$))
			.subscribe((message) => {
				this.filePendingReviewers = this.filePendingReviewers.map((val) => ({ ...val, message }));
			});
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			reviewers: new FormControl([], Validators.minLength(1)),
			role: new FormControl(this.reviewerRoleLabels[0]),
			message: new FormControl(''),
			feedbackDueDate: new FormControl(undefined, Validators.required),
		});

		this._listenForFormChanges();
		// Set default feedback due date for non initial reviews
		if (this.data.file && !this.data.isInitialReview) {
			this.form.get('feedbackDueDate')?.setValue(this.data.file.feedbackDueDate);
		}

		if (this.data.autoAddSelfAsReviewer && this.data.isInitialReview) {
			this._autoAddSelfAsReviewer();
		}
	}

	private _autoAddSelfAsReviewer(): void {
		const sessionUser = this.sessionStore.getValue().profile;
		this.form.get('reviewers')?.patchValue([
			{
				...sessionUser,
				nameFirst: sessionUser?.privateProfile?.nameFirst,
				nameLast: sessionUser?.privateProfile?.nameLast,
			} as Profile,
		]);
		this.form.get('role')?.patchValue(this.reviewerRoleLabels[1]);
		this.addReviewers();
		this.form.get('role')?.patchValue(this.reviewerRoleLabels[0]);
	}

	addReviewers(): void {
		const reviewers = this.form.get('reviewers').value?.map((reviewer) => reviewer.email || reviewer.name);
		const role = this.form.get('role').value.value;
		const message = this.form.get('message').value;
		const feedbackDueDate = this.form.get('feedbackDueDate').value;

		if (this.data.previewOnly) {
			const foundExistingReviewers = this.form
				.get('reviewers')
				.value.find(
					(val) =>
						(this.data.file.reviewers ?? [])?.find((val2) => val2.id === val.id) ||
						(this.data.file.approvers ?? [])?.find((val2) => val2.id === val.id)
				);

			const foundPendingReviewers = this.filePendingReviewers.find(
				(curr) =>
					(this.form.get('reviewers').value ?? [])?.find((rev) => (curr.reviewers ?? []).find((val) => val.id === rev.id)) &&
					curr.role === role &&
					curr.type === FilePendingReviewerType.CREATE
			);

			if (!foundExistingReviewers && !foundPendingReviewers) {
				this.filePendingReviewers = [
					...this.filePendingReviewers,
					{
						type: FilePendingReviewerType.CREATE,
						reviewers: this.form.get('reviewers').value ?? [],
						role,
						message,
						feedbackDueDate,
					},
				];
			}
			this.form.patchValue({ reviewers: [] }, { emitEvent: false });
			return;
		}

		// Construct the dto
		this.fileService
			.addReviewers(this.data.file, reviewers, role, message, feedbackDueDate)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(newFile: PublicFile) => {
					this.form.patchValue({ reviewers: [], message: '' }, { emitEvent: false });
					this.data.file = newFile;
				},
				(error) => this.globalService.triggerErrorMessage(error)
			);
	}

	removeReviewer(user: User, role: ReviewerRole): void {
		if (this.data.previewOnly) {
			this.data = {
				...this.data,
				file: {
					...this.data.file,
					approvers: this.data.file.approvers?.filter(
						(reviewer) => !(reviewer?.id === user?.id && role === ReviewerRole.Approver)
					),
					reviewers: this.data.file.reviewers?.filter(
						(reviewer) => !(reviewer?.id === user?.id && role === ReviewerRole.Reviewer)
					),
				},
			};
			// Prevent duplicates
			if (
				!this.filePendingReviewers.some(
					(val) => val.type === FilePendingReviewerType.REMOVE && val.userId === user?.id && val.role === role
				)
			) {
				this.filePendingReviewers = [
					...this.filePendingReviewers,
					{
						type: FilePendingReviewerType.REMOVE,
						userId: user.id,
						role,
					},
				];
			}
			return;
		}
		this.fileService
			.removeReviewers(this.data.file, [user.id], role)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(newFile: PublicFile) => {
					this.data.file = newFile;
				},
				(error) => this.globalService.triggerErrorMessage(error)
			);
	}

	dismiss(withData: boolean = false): void {
		if (withData) {
			this.dialogRef.close(this.filePendingReviewers);
		} else {
			this.dialogRef.close();
		}
	}

	removePendingReviewer(reviewer: User, role: ReviewerRole): void {
		// Filter or remove the pending reviewer
		this.filePendingReviewers = this.filePendingReviewers
			.filter((val) => {
				if (val.reviewers?.length > 1) {
					return true;
				}
				return !(val.reviewers?.includes(reviewer) && val.role === role);
			})
			.map((val) => {
				if (val.reviewers?.length > 1) {
					return { ...val, reviewers: val.reviewers.filter((curr) => curr !== reviewer) };
				}
				return val;
			});
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}
}
