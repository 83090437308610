import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { of, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { FormPropertyUpdateObject } from '../../../state/global/global.model';
import { OverlayConfig } from '@angular/cdk/overlay';

/**
 * Autocomplete Angular Material chips input
 */
@Component({
	selector: 'app-autocomplete',
	templateUrl: './autocomplete.component.html',
	styleUrls: ['./autocomplete.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() label;
	@Input() visible = true;
	@Input() selectable = true;
	@Input() removable = true;
	@Input() allItems: any[] = [];
	@Input() items: any[] = [];
	@Input() formGroup: FormGroup;
	@Input() controlName: string;
	@Input() required = false;
	@Input() canAddNew = false;
	@Input() addDescription = 'Add an item...';
	@Input() filteredItemsAPI: () => void;
	@Input() floatLabel = 'auto';
	@Input() tooltip: string;
	@Input() showSelectAll = false;
	@Input() mode: 'single' | 'multiple' = 'multiple';
	@Input() seperatorKeyCodes: number[] = [ENTER, COMMA];
	@Input() itemParentKey: string;
	@Input() showClearInput: boolean;
	@Input() readonly: boolean = false;
	@Input() tooltipOptions: { identifier: string; tooltip: string }[] = [];

	@Input()
	public get filteredItems() {
		return this._filteredItems;
	}

	public set filteredItems(newValue) {
		if (this.mode === 'multiple') {
			this._filteredItems = newValue?.filter(
				(entity) => !this.formGroup.get(this.controlName)?.value?.find((e) => e.id === entity.id)
			);
		} else {
			this._filteredItems = newValue;
		}
	}

	// tslint:disable-next-line: variable-name
	private _filteredItems: any[];

	@Output() valueChange: EventEmitter<any> = new EventEmitter();
	@Output() fullValueChange: EventEmitter<FormPropertyUpdateObject> = new EventEmitter();
	@Output() typing: EventEmitter<string> = new EventEmitter();
	@Output() changed: EventEmitter<any> = new EventEmitter();
	@Output() added: EventEmitter<any> = new EventEmitter();
	@Output() removed: EventEmitter<any> = new EventEmitter();

	@ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
	@ViewChild('auto') matAutocomplete: MatAutocomplete;
	@ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

	public itemCtrl = new FormControl();
	public currentQuery = '';

	private readonly _unsubscribe$: Subject<void> = new Subject();

	constructor() {}
	ngAfterViewInit(): void {
		this.trigger['_getOverlayConfig'] = (): OverlayConfig => {
			return new OverlayConfig({
				positionStrategy: this.trigger['_getOverlayPosition'](),
				scrollStrategy: this.trigger['_scrollStrategy'](),
				width: this.trigger['_getPanelWidth'](),
				direction: this.trigger['_dir'] ?? undefined,
				panelClass: this.trigger['_defaults']?.overlayPanelClass,
				hasBackdrop: true,
				backdropClass: 'cdk-overlay-transparent-backdrop',
			});
		};
	}

	getParentLevels = (item, allItems: any[]): number => {
		if (!item[this.itemParentKey]) {
			return 0;
		} else if (item[this.itemParentKey] && !allItems?.find((val) => val.id === item[this.itemParentKey]?.id)?.[this.itemParentKey]) {
			return 1;
		} else if (item[this.itemParentKey] && !!allItems?.find((val) => val.id === item[this.itemParentKey]?.id)?.[this.itemParentKey]) {
			return 2;
		}
	};

	ngOnInit(): void {
		if (this.formGroup) {
			this.itemCtrl.patchValue(this.formGroup.get(this.controlName).value);

			// Emit value changes to parent
			// Hack: Had to add a timeout, because this was firing before the value was really set?
			// So the api call was a step behind from the state.
			this.formGroup
				.get(this.controlName)
				.valueChanges.pipe(takeUntil(this._unsubscribe$))
				.subscribe((value) =>
					setTimeout(() => {
						if (this.mode === 'single') {
							value = value?.[0];
						}

						console.log('Autocomplete value changed', value);
						this.valueChange.emit(value);
						this.fullValueChange.emit(value);
					}, 100)
				);

			// Filter our autocomplete to not include previously added values
			this.itemCtrl.valueChanges
				.pipe(
					// startWith(this.currentQuery),
					debounceTime(400),
					map((text) => {
						// This can be a string being typed, or the group of objects that are in the input
						// Its weird. But we can just check and return suggestions when its a string.
						console.log('Autocomplete value changed', text);
						if (typeof text === 'string') {
							this.getFilteredItems(text);
						} else {
							return of([]);
						}
					}),
					takeUntil(this._unsubscribe$)
				)
				.subscribe();
		} else {
			console.warn('Couldnt find a FormGroup Input', this.controlName);
		}
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	/**
	 * Add an item to the form field if that is allowed
	 * @param event
	 */
	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		if (this.mode === 'single' && this.items.length > 0) {
			return;
		}

		if (this.canAddNew) {
			const items = [...this.formGroup.value[this.controlName]];

			// Add our item
			items.push({
				id: uuidv4(),
				add: true,
				name: value,
			});

			this.formGroup.patchValue({ [this.controlName]: items });
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}

		this.added.emit();
	}

	/**
	 * Remove an item from the value's array
	 * @param item
	 */
	remove(item): void {
		const items = [...this.formGroup.value[this.controlName]];
		const index = items.findIndex((i) => i.id === item.id);
		// console.log('Autocomplete: remove', items, index);

		if (index >= 0) {
			items.splice(index, 1);
		}

		// console.log('Autocomplete: removed', items, index);

		this.formGroup.patchValue({ [this.controlName]: items });
		this.removed.emit(item);
	}

	/**
	 * When the user selects a value, add it to the form value
	 * @param event
	 */
	selected(event: MatAutocompleteSelectedEvent): void {
		this.itemInput.nativeElement.value = '';
		const initialValue = this.formGroup.value[this.controlName] || [];

		let value = event.option.value;

		if (this.mode === 'single' && initialValue?.length > 0) {
			console.log('Single mode, and already has a value', initialValue);
			return;
		}

		if (value === 'selectAll') {
			// Put all items together and filter by unique id
			value = [...initialValue, ...this.filteredItems].filter((e, i, a) => a.findIndex((t) => t.id === e.id) === i);

			console.log('Select all', value, initialValue, this.filteredItems);

			this.formGroup.patchValue({
				[this.controlName]: value,
			});
		} else {
			if (this.mode === 'single') {
				// If we are in single mode, just replace the value
				this.formGroup.patchValue({
					[this.controlName]: value,
				});

				this.itemCtrl.patchValue(this.formGroup.get(this.controlName).value);
			} else {
				this.formGroup.patchValue({
					[this.controlName]: [...initialValue, value],
				});
			}
		}

		this.changed.emit(value);
	}

	/**
	 * Returns items that match the query string passed in
	 * @param text
	 */
	getFilteredItems(text: string): void {
		this.currentQuery = text;
		console.log('Filtering items', text, this.allItems);

		if (this.allItems?.length) {
			this.filteredItems = this.allItems?.filter((item) => {
				if (this.mode === 'multiple') {
					// Don't return anything that already exists in this value
					const value = this.formGroup.get(this.controlName)?.value;

					const isArray = Array.isArray(value);
					if (isArray) {
						// this.formGroup.get(this.controlName)?.value?.find(i => i.id === item.id)
						if (value.find((i) => i.id === item.id)) {
							return false;
						}
					} else {
						if (value?.id === item.id) {
							return false;
						}
					}
				}

				// Don't return that doesnt match any text being typed
				return item.name?.toLowerCase().indexOf(text?.toLowerCase()) > -1;
			});
		} else {
			this.typing.emit(text);
		}
	}

	displayWithName(item): string {
		if (item === null || Array.isArray(item)) {
			return '';
		}
		let name = item?.name || item?.nameFirst + ' ' + item?.nameLast;
		if (item?.parent) {
			name = '(' + item.parent?.name + ') ' + name;
		}
		return name || item;
	}

	handleClose(event): void {
		if (event.relatedTarget && event.relatedTarget.tagName === 'MAT-OPTION') {
			return;
		} else {
			Promise.resolve().then(() => this.trigger.closePanel());
		}
	}

	onClearInput(): void {
		this.formGroup.patchValue(
			{
				[this.controlName]: null,
			},
			{ emitEvent: false }
		);
		this.valueChange.emit(null);
		this.itemCtrl.patchValue(this.formGroup.get(this.controlName).value, { emitEvent: false });
	}

	getTooltip(item: any, options: { identifier: string; tooltip: string }[]): string {
		const itemValue = item?.id || item?.name;
		if (!options || !options.length || !itemValue) {
			return '';
		}
		const option = options.find((o) => o.identifier === item.id);
		return option?.tooltip || '';
	}
}
