import { AggregateFunction } from '../../../../../../../api/src/_core/models/math-operations';
import { Tactic } from '../../tactic/tactic.model';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MeasurementAggregationService {
	constructor() {}

	getSpend = (tactics: Tactic[], operation: string = AggregateFunction.Sum): number =>
		this._reducer(this.getSpends(tactics), tactics, operation);

	getSpends = (tactics: Tactic[]): number[] => tactics.map(t => Number(t.budgetCache?.spendEstimated) || 0);

	getBenchmark = (measurementTypeName: string, tactics: Tactic[], operation: string = AggregateFunction.MeanWeighted): number =>
		this._reducer(this.getBenchmarks(tactics, measurementTypeName), tactics, operation);

	getBenchmarks = (tactics: Tactic[], measurementTypeName: string): number[] =>
		tactics.map(tactic => Number(tactic.measurements?.find(m => m.measurementType.name === measurementTypeName)?.benchmark) || 0);

	getMeasurement = (measurementTypeName: string, tactics: Tactic[], operation: string = AggregateFunction.MeanWeighted) =>
		this._reducer(this.getMeasurements(tactics, measurementTypeName), tactics, operation);

	getMeasurements = (tactics: Tactic[], measurementTypeName: string): number[] =>
		tactics.map(tactic => Number(tactic.measurements?.find(m => m.measurementType.name === measurementTypeName)?.value) || 0);

	private _reducer(measurements: number[], tactics: Tactic[], operation: string): any {
		switch (operation) {
			case AggregateFunction.MeanWeighted:
				const spends = tactics.map(t => Number(t.budgetCache?.spendEstimated));
				const spendTotal = spends.reduce((a, b) => a + b, 0);
				const weights = spends.map(s => s / spendTotal);

				const [sum, weightSum] = weights.reduce(
					(acc, curr, i) => {
						acc[0] = acc[0] + measurements[i] * curr;
						acc[1] = acc[1] + curr;
						return acc;
					},
					[0, 0]
				);

				return sum / weightSum;

			case AggregateFunction.Mean:
				return measurements.reduce((acc, curr) => (acc + curr), 0) / tactics.length;

			case AggregateFunction.Count:
				return measurements?.length;

			case AggregateFunction.First:
				return measurements?.length ? measurements[0] : 0;
			case AggregateFunction.Join:
				return measurements.join(',');

			case AggregateFunction.Mode:
				return measurements.join(',');
			case AggregateFunction.Median:
				measurements.sort((a, b) => a - b);
				const mid = Math.floor(measurements.length / 2);
				if (measurements.length % 2 === 1) {
					return measurements[mid];
				} else {
					return (measurements[mid - 1] + measurements[mid]) / 2;
				}

			case AggregateFunction.Sum:
			default:
				return measurements.reduce((acc, curr) => acc + curr, 0);
				break;
		}
	}
}
