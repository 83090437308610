import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PublicFile, PublicFileVersion } from '../../../../../../../api/src/file/file.entity';
import { Entities } from '../../../../state/global/global.model';
import { GlobalService } from '../../../../state/global/global.service';
import { Plan } from '../../plan/plan.model';
import { Program } from '../../program/program.model';
import { FileService } from '../file.service';
import { FileQuery } from '../file.query';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-file-modal',
	templateUrl: './file-modal.component.html',
	styleUrls: ['./file-modal.component.scss'],
})
export class FileModalComponent implements OnInit, OnDestroy {
	@Input() file: PublicFile;
	@Input() disableFileReview: boolean = false;

	public selectedFileVersionIdMap: Map<string, string> = new Map<string, string>();
	public file$: Observable<PublicFile>;

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		public dialogRef: MatDialogRef<FileModalComponent>,
		private readonly globalService: GlobalService,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			id: Program['id'] | Plan['id'];
			entityType: Entities;
			file: PublicFile;
			showGoToProgram?: boolean;
			showProgram?: boolean;
			showTactic?: boolean;
		},
		private readonly fileQuery: FileQuery,
		private readonly fileService: FileService
	) {}

	ngOnInit(): void {
		this.file$ = this.fileQuery
			.selectEntity(this.data.file.id)
			.pipe(map((file) => file || this.data.file))
			.pipe(takeUntil(this._unsubscribe$));
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	cancel(): void {
		this.dialogRef.close();
	}

	isImage(file: PublicFile): boolean {
		return this.fileService.isImage(file.mimeType);
	}

	onSetFileVersion(fileVersion: PublicFileVersion, fileId: string): void {
		this.selectedFileVersionIdMap.set(fileId, fileVersion.id);
	}

	download(): void {
		this.fileService
			.download(this.data.file.path)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				() => {},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	copyFilePath(): void {
		const file = this.file || this.data?.file;
		const link = `${window.location.origin}/program/${file.programId}/files?fileId=${file.id}`;
		navigator.clipboard.writeText(link);
		this.globalService.triggerSaveMessage('Link copied to clipboard');
	}
}
