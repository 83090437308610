import { isArray } from '@datorama/akita';
import { BudgetDistributionUtils } from '../../../../../api/src/budget-distribution/budget-distribution.utils';
import { Entities } from '../global/global.model';
import { TableRow } from '../table/table.model';
import { FilterParameters } from './filter/filter.model';

export enum FacetType {
	PlanFacets = 'planFacets',
	ProgramFacets = 'programFacets',
	TacticFacets = 'tacticFacets',
	InvoiceFacets = 'invoiceFacets',
}

/**
 * Finds the correct budgetCache value for any TableRow type.
 */
export function getTableRowBudget(entity: TableRow<any>, isPlan: boolean = false) {
	switch (entity.type?.toLowerCase()) {
		case 'plan':
			return isPlan ? entity?.budgetCache?.amountPlanned?.rawValue : entity.budgetCache?.amountPlanned?.rawValue;
		case 'program':
			return isPlan ? entity?.budgetCache?.amountPlanned?.rawValue : entity.budgetCache?.spendEstimated?.rawValue;
		case 'tactic':
			if (entity.brandCaches) return entity.brandCaches.reduce((a, b) => a + b.spendEstimated || 0, 0);
			return isPlan ? entity?.budgetCache?.amountPlanned?.rawValue : entity.budgetCache?.spendEstimated?.rawValue;
		case 'invoice':
			return entity.amount;

		default:
			// This means it's a grouped entity. We will return value if it exists
			return isPlan ? entity?.budgetCache?.amountPlanned?.rawValue : entity.budgetCache?.spendEstimated?.rawValue;
	}
}

/**
 * Finds the correct budget array for any TableRow type.
 */
export function getTableRowBudgetDetails(entity: TableRow<any>, isPlan: boolean = false) {
	switch (entity.type?.toLowerCase()) {
		case 'plan':
		case 'program':
			return entity?.budgetAllocations;
		case 'tactic':
			return entity?.costs;
		case 'invoice':
			return entity.amount;

		default:
			return undefined;
	}
}

/**
 * Return the proper total key that needs to be used for the given entity type within the right
 * budget mode.
 */
export function getTableRowBudgetTotalKey(entityType: Entities, isPlan: boolean = false) {
	switch (entityType.toLowerCase()) {
		case 'plan':
			return isPlan ? 'amountPlanned' : 'amountPlanned';
		case 'program':
		case 'budget-allocation':
			return isPlan ? 'amountPlanned' : 'amountActual';
		case 'tactic':
		case 'cost':
			return isPlan ? 'amountFromPlan' : 'amountPlanned';
		case 'invoice':
			return 'amount';

		default:
			return undefined;
	}
}

/**
 * Returns merged budget distributions for a TableRow.
 */
export function getTableRowBudgetDistributions(entity: TableRow<any>, isPlan: boolean = false) {
	const type = entity.type?.toLowerCase();
	let totalKey;
	let brandAllocationKey;
	let budgetItems;

	switch (type) {
		case 'program':
			totalKey = getTableRowBudgetTotalKey(type, isPlan);
			brandAllocationKey = getBudgetEntityBrandAllocationKey('budget-allocation', isPlan);
			budgetItems = entity.budgetAllocations?.rawValue || entity.budgetAllocations || [];
			break;

		case 'tactic':
			totalKey = getTableRowBudgetTotalKey(type, isPlan);
			brandAllocationKey = 'brandAllocations';
			budgetItems = entity.costs?.rawValue || entity.costs || [];
			break;

		default:
			return undefined;
	}
	if (isArray(budgetItems) || budgetItems?.length) {
		const distributions = BudgetDistributionUtils.getMergedDistributionsWithTotals(budgetItems, totalKey, brandAllocationKey);

		// Now merge the distributions with the budget distribution groups so that we have custom data.
		const groups = budgetItems.map((budgetItem) => budgetItem.budgetDistributionGroups || []).reduce((acc, val) => acc.concat(val), []);
		return BudgetDistributionUtils.mergeBudgetDistributionGroupsWithMergedBudgetDistributions(distributions, groups);
	} else {
		return [];
	}
}

export function getFacetType(filters: FilterParameters): FacetType {
	let type = FacetType.PlanFacets;
	if (filters?.include?.id?.match(/^program/)) {
		type = FacetType.ProgramFacets;
	} else if (filters?.include?.id?.match(/^tactic/)) {
		type = FacetType.TacticFacets;
	} else if (filters?.include?.id?.match(/^invoice/)) {
		type = FacetType.InvoiceFacets;
	}
	return type;
}

export function getFacetEntityName(facetType: FacetType) {
	switch (facetType) {
		case FacetType.PlanFacets:
			return 'Plan';
		case FacetType.ProgramFacets:
			return 'Program';
		case FacetType.TacticFacets:
			return 'Tactic';
		case FacetType.InvoiceFacets:
			return 'Invoice';
	}
}

export function getBudgetEntityBrandAllocationKey(entityType: 'budget-allocation' | 'cost', isPlan: boolean = false) {
	switch (entityType) {
		case 'budget-allocation':
			return isPlan ? 'brandAllocationsPlanned' : 'brandAllocationsActual';
		case 'cost':
			return 'brandAllocations';
	}
}

export function getPluralEntityName(entityName: string, capitalize: boolean = true) {
	const plural = EntityPlurals[entityName.toLowerCase()];

	if (capitalize) {
		return plural.charAt(0).toUpperCase() + plural.slice(1);
	} else {
		return plural;
	}
}

/**
 * Describes all the plurals for major entities.
 */
export const EntityPlurals = {
	plan: 'plans',
	program: 'programs',
	tactic: 'tactics',
	invoice: 'invoices',
	retailer: 'retailers',
	brand: 'brands',
};

export const EntitySingles = {
	plans: 'plan',
	programs: 'program',
	tactics: 'tactic',
	invoices: 'invoice',
	retailers: 'retailer',
	brands: 'brand',
};
