import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BrandAllocations } from '../../global/global.model';
import { GlobalQuery } from '../../global/global.query';
import { Brand } from '../brand/brand.model';
import { BudgetAllocation, BudgetAllocationGroup } from './budget-allocation.model';
import { BudgetAllocationStore } from './budget-allocation.store';
import { Tactic } from '../tactic/tactic.model';
import { Program } from '../program/program.model';
import { SnapshotAnalyticsData } from '../../global/snapshot-analytics-data.model';
import { MeasurementAggregationService } from '../measurement/measurement-summary/measurement-aggregation.service';

/**
 * Budget Allocation Service
 * This service handles the API and entity logic for budget allocations
 */
@Injectable({ providedIn: 'root' })
export class BudgetAllocationService {
	constructor(
		private budgetAllocationStore: BudgetAllocationStore,
		private http: HttpClient,
		private globalQuery: GlobalQuery,
		private readonly measurementAggregationService: MeasurementAggregationService
	) {}

	/**
	 * No API call needed for this currently.  Components pull these items from the Global Service settings object.
	 */
	get(params: any) {}

	/**
	 * Set the current budget allocations in the Akita store
	 */
	set(budgetAllocations: BudgetAllocation[]) {
		this.budgetAllocationStore.set(budgetAllocations.map((allocation) => this.prepareForAkita(allocation)));
	}

	/**
	 * Upsert budget allocations into the Akita store
	 */
	upsertMany(budgetAllocations: BudgetAllocation[]) {
		this.budgetAllocationStore.upsertMany(budgetAllocations.map((allocation) => this.prepareForAkita(allocation)));
	}

	/**
	 * Create a new budget allocation on the API
	 */
	create(entity: 'plan' | 'program', entityId: string, budgetAllocation: BudgetAllocation) {
		this.budgetAllocationStore.setLoading(true);

		return this.http
			.post<BudgetAllocation>(
				`${environment.apiUrl}/organization/${environment.organizationId}/${entity}/${entityId}/budget-allocation`,
				this.prepareForApi(budgetAllocation)
			)
			.pipe(
				tap((newValue) => {
					this.budgetAllocationStore.update(budgetAllocation.id, this.prepareForAkita(newValue));
					this.budgetAllocationStore.setLoading(false);
				})
			);
	}

	/**
	 * Add a budget allocation to the Akita store.
	 */
	add(budgetAllocation: BudgetAllocation) {
		this.budgetAllocationStore.add(budgetAllocation);
	}

	/**
	 * Update a budget allocation's fields on the API
	 * If the budget allocation doesn't have a `created` field, it will only be updated in the Akita store.
	 * This is for new budget allocations before they are saved to the API.
	 */
	update(entity: 'plan' | 'program', entityId: string, id: BudgetAllocation['id'], budgetAllocation: Partial<BudgetAllocation>) {
		this.budgetAllocationStore.setLoading(true);

		if (budgetAllocation.created) {
			// API doesn't like planId on update.
			const preparedBudgetAllocation = {
				...this.prepareForApi(budgetAllocation),
				planId: undefined,
			};

			return this.http
				.put<BudgetAllocation>(
					`${environment.apiUrl}/organization/${environment.organizationId}/${entity}/${entityId}/budget-allocation/${id}`,
					preparedBudgetAllocation
				)
				.pipe(
					tap((newValue) => {
						this.budgetAllocationStore.update(budgetAllocation.id, this.prepareForAkita(newValue));
						this.budgetAllocationStore.setLoading(false);
					}),
					// Undo changes on Akita if there are any errors
					catchError((err) => {
						this.budgetAllocationStore.update(
							budgetAllocation.id,
							this.budgetAllocationStore.getValue().entities[budgetAllocation.id]
						);
						return throwError(err);
					})
				);
		} else {
			console.log('Updating Akita', budgetAllocation);
			this.budgetAllocationStore.update(budgetAllocation.id, budgetAllocation);
			return of(budgetAllocation);
		}
	}

	/**
	 * Remove a budget allocation from the API.
	 */
	remove(entity: 'plan' | 'program', entityId: string, id: BudgetAllocation['id']) {
		this.budgetAllocationStore.setLoading(true);

		return this.http
			.delete<BudgetAllocation>(
				`${environment.apiUrl}/organization/${environment.organizationId}/${entity}/${entityId}/budget-allocation/${id}`
			)
			.pipe(
				tap((newValue) => {
					this.budgetAllocationStore.remove(id);
					this.budgetAllocationStore.setLoading(false);
				})
			);
	}

	/**
	 * Normalize a budget allocation for Akita
	 */
	prepareForAkita(budgetAllocation: Partial<BudgetAllocation>): BudgetAllocation {
		const obj: Partial<BudgetAllocation> = {};
		const settings = this.globalQuery.getValue().settings;

		if (budgetAllocation) {
			Object.keys(budgetAllocation).forEach((key) => {
				switch (key) {
					case 'brandAllocationsActual':
					case 'brandAllocationsPlanned':
						// Reconcile with brand objects if they aren't already fixed
						if (budgetAllocation[key]?.length && !budgetAllocation[key][0].name) {
							obj[key] = budgetAllocation[key]?.map((allocation) => ({
								...allocation,
								...(settings.brands.find((b) => b.id === allocation.brand.id) || {}),
							}));
						} else {
							obj[key] = budgetAllocation[key];
						}
						break;

					case 'amountActual':
					case 'amountPlanned':
						obj[key] = Number(budgetAllocation[key]);
						break;

					default:
						obj[key] = budgetAllocation[key];
						break;
				}
			});
		}

		return obj as BudgetAllocation;
	}

	/**
	 * Normalize a budget allocation for the API
	 */
	prepareForApi(budgetAllocation: Partial<BudgetAllocation>) {
		const obj = {};

		console.log('Preparing for API', budgetAllocation);

		if (budgetAllocation) {
			Object.keys(budgetAllocation).forEach((key) => {
				switch (key) {
					case 'fundingSource':
					case 'fundingType':
						obj[key + 'Id'] = budgetAllocation[key]?.id;
						break;

					case 'amountPlanned':
					case 'amountActual':
						obj[key] = (budgetAllocation[key] || 0)?.toString();
						break;

					case 'id':
					case 'created':
					case 'author':
					case 'programId':
						break;

					case 'brandAllocationsActual':
					case 'brandAllocationsPlanned': {
						// check if user added a brand (new brand does not have split property yet)
						// - if yes and they used custom distribution, then new brand should have split value 0
						// - if yes and they used automatic distribution, then new brand should have split value 1/number of brands
						const brands = budgetAllocation[key] || [];

						// Calculate the total sum of splits
						const hasNewBrand = brands.find((b) => b?.split == null);
						const existingBrands = brands.filter((b) => b?.split != null);
						const existingAreEvenlyDistributed = existingBrands.every(
							(b) => Math.abs(b.split - 1 / existingBrands.length) < 0.00001
						);
						brands.reduce((acc, brand) => acc + parseFloat(brand?.split?.toString()), 0) === 1;
						const sum = brands.reduce((acc, ba) => acc + Math.round(+(ba?.split || 0) * 100), 0);
						const sumIsFull = sum / 100 === 1;

						obj['brandAllocations'] = brands.map((brand) => {
							return {
								brandId: brand.id || brand.brandId,
								split: hasNewBrand
									? existingAreEvenlyDistributed
										? String(1 / brands.length) // Automatic distribution
										: brand?.split?.toString() || '0' // Custom distribution
									: sumIsFull
									? brand.split.toString()
									: String(1 / brands.length),
								distributions: (brand.budgetDistributions || brand.distributions)?.map((distribution) => ({
									...distribution,
									id: undefined,
									brandAllocationId: undefined,
									split: distribution.split?.toString(),
								})),
							};
						});
						break;
					}

					default:
						obj[key] = budgetAllocation[key];
						break;
				}
			});
		}

		console.log('Prepared for API', obj);

		return obj;
	}

	/**
	 * Return the total amount of all budget allocations passed in.
	 */
	getTotal(budgetAllocations: BudgetAllocation[], fieldName: string = 'amountActual') {
		return budgetAllocations?.reduce((a, b) => a + (Number(b[fieldName]) || 0), 0) || 0;
	}

	/**
	 * Return the total amount of all budget allocations passed in, grouped by brands individual splits of the total.
	 */
	getTotalWithBrandSplits(total: number, brandAllocations: BrandAllocations[], brands: Brand[]) {
		const brandIds = brands.map((b) => b.id);

		// Get Brand split total
		const splitTotal = brandAllocations
			.map((bA) => {
				if (bA.brand && brandIds.includes(bA.brand.id)) {
					return Number(bA.split);
				} else {
					return 0;
				}
			})
			.reduce((a, b) => a + b, 0);

		// Multiply total by the total split for the brands
		return total * splitTotal;
	}

	/**
	 * Return the total amount of all budget allocations passed in that match the brands included.
	 */
	getAllocationTotalForBrands(allocations: BudgetAllocation[], brands: Brand[], fieldSuffix: string = 'Actual') {
		return (
			allocations
				?.filter((budgetAllocation) =>
					budgetAllocation?.[`brandAllocations${fieldSuffix}`]?.find((brandAllocation) =>
						brands?.map((b) => b.name).includes(brandAllocation?.brand?.name || brandAllocation?.name)
					)
				)
				.map((budgetAllocation) => {
					// const split = budgetAllocation?.brandAllocationsActual.find(brandAllocation => brandAllocation.name === //[`brandAllocations${fieldSuffix}`].
					return this.getTotalWithBrandSplits(
						budgetAllocation?.[`amount${fieldSuffix}`],
						budgetAllocation?.[`brandAllocations${fieldSuffix}`],
						brands
					);
				})
				.reduce((a, b) => a + Number(b), 0) || 0
		);
	}

	/**
	 * Return the total amount of each brand for the included budget allocations as an object with brands as keys and amounts as values.
	 */
	getBrandTotals(budgetAllocations: BudgetAllocation[], fieldSuffix: string = 'Actual') {
		const obj = {};

		budgetAllocations?.forEach((bA) => {
			if (bA) {
				// Get the brands and then split the amount
				bA[`brandAllocations${fieldSuffix}`]?.forEach((brand) => {
					// Find the name depending on if we're using the flattend version or not
					const name = brand?.name || brand?.brand?.name;

					// Create an object
					if (!obj[name]) {
						obj[name] = 0;
					}

					// Increment the value of our amount / # of brands
					// obj[name] += bA[`amount${fieldSuffix}`] / bA[`brandAllocations${fieldSuffix}`].length;
					obj[name] += bA[`amount${fieldSuffix}`] * brand.split;
				});
			}
		});

		return obj;
	}

	/**
	 * Return budget allocation amounts grouped by funding source and type.
	 */
	getBudgetAllocationTotalsByFundingSource(
		budgetAllocations: BudgetAllocation[],
		budgetKey: 'amountActual' | 'amountPlanned' = 'amountActual'
	) {
		const obj: any = {};

		budgetAllocations?.forEach((bA) => {
			// Source
			if (!obj[bA?.fundingSource?.name]) {
				obj[bA?.fundingSource?.name] = +bA[budgetKey];
			} else {
				obj[bA?.fundingSource?.name] += +bA[budgetKey];
			}
		});

		return obj;
	}

	/**
	 * Return budget allocation amounts grouped by funding source and type.
	 */
	groupBudgetAllocations(budgetAllocations: BudgetAllocation[], budgetKey: 'amountActual' | 'amountPlanned' = 'amountActual') {
		const obj: BudgetAllocationGroup = {};

		budgetAllocations?.forEach((bA) => {
			// Source
			if (!obj[bA?.fundingSource?.name]) {
				obj[bA?.fundingSource?.name] = {};
			}

			// Type
			if (!obj[bA?.fundingSource?.name][bA?.fundingType.name]) {
				obj[bA?.fundingSource?.name][bA?.fundingType.name] = +bA[budgetKey];
			} else {
				// Add to total
				obj[bA?.fundingSource?.name][bA?.fundingType.name] += +bA[budgetKey];
			}
		});

		return obj;
	}

	/**
	 * Return spend by peso
	 */
	getSpendByPESO(tactics: Tactic[]): { [key: string]: number } {
		const obj: { [key: string]: number } = {};

		tactics?.forEach((tactic) => {
			obj[tactic.tacticType.mediaType] = tactics
				.filter((val) => val.tacticType.mediaType === tactic.tacticType.mediaType)
				.reduce((previousValue, currentValue) => previousValue + currentValue?.budgetCache.spendEstimated, 0);
		});

		return obj;
	}

	/**
	 * Return spend by program
	 */
	getSpendByProgram(programs: Program[]): SnapshotAnalyticsData[] {
		const data: SnapshotAnalyticsData[] = [];
		programs?.forEach((program) => {
			data.push({
				key: program?.id,
				viewValue: program.name,
				value: program?.budgetCache?.spendEstimated ?? 0,
			});
		});
		return data;
	}

	/**
	 * Return spend by tactic
	 */
	getSpendByTactic(tactics: Tactic[]): SnapshotAnalyticsData[] {
		const data: SnapshotAnalyticsData[] = [];
		tactics?.forEach((tactic) => {
			data.push({
				key: tactic?.id,
				viewValue: tactic.name,
				value: tactic?.budgetCache?.spendEstimated ?? 0,
				meta: {
					measurements: tactic?.measurements ?? [],
					...tactic?.measurements.reduce((result, b) => {
						if (result[b.measurementType.name]) {
							result[b.measurementType.name] += b.value;
						} else {
							result = { ...result, ...{ [b.measurementType.name]: b.value } };
						}

						return result;
					}, {}),
				},
			});
		});
		return data;
	}

	/**
	 * Return vendor spend by tactic
	 */
	getVendorSpendsByTactic(tactics: Tactic[]) {
		const data = [];

		tactics?.forEach((tactic) => {
			data.push({
				key: tactic?.id,
				viewValue: tactic.name,
				estimatedSpend: tactic?.budgetCache?.spendEstimated ?? 0,
				actualSpend: tactic?.budgetCache?.spendActual ?? 0,
				meta: {
					measurements: tactic?.measurements ?? [],
					...tactic?.measurements.reduce((result, b) => {
						if (result[b.measurementType.name]) {
							result[b.measurementType.name] += b.value;
						} else {
							result = { ...result, ...{ [b.measurementType.name]: b.value } };
						}

						return result;
					}, {}),
				},
			});
		});

		return data;
	}

	getSpendByPrograms(programs: Program[]): SnapshotAnalyticsData[] {
		const data: SnapshotAnalyticsData[] = [];
		programs?.forEach((program) => {
			data.push({
				key: program?.id,
				viewValue: program.name,

				value: this.getSpendByTactic(program?.tactics ?? [])?.reduce((a, b) => a + b.value, 0),
			});
		});
		return data;
	}

	getSpendByRetailers(programs: Program[]): SnapshotAnalyticsData[] {
		let data: SnapshotAnalyticsData[] = [];
		programs.forEach((program) => {
			const programsByRetailer = programs?.filter((val) => val?.retailer?.id === program?.retailer?.id);

			if (!data.find((val) => val?.key === program?.retailer?.id)) {
				data.push({
					key: program?.retailer?.id,
					viewValue: program?.retailer?.name,
					meta: program?.tactics
						.map((t) => t.measurements || [])
						?.reduce((acc, measurements) => acc.concat(measurements), [])
						.reduce((result, b) => {
							return {
								...result,
								...{
									[b.measurementType.name]: this.measurementAggregationService.getMeasurement(
										b.measurementType.name,
										program?.tactics,
										b?.measurementType?.aggregations?.primary?.reduceOperation
									),
								},
							};
						}, {}),
					value: this.getSpendByPrograms(programsByRetailer)?.reduce((a, b) => a + b.value, 0),
				});
			} else {
				data = data.map((val) => {
					if (val.key === program?.retailer?.id) {
						val = {
							...val,
							meta: program?.tactics
								.map((t) => t.measurements || [])
								?.reduce((acc, measurements) => acc.concat(measurements), [])
								.reduce((result, b) => {
									return {
										...result,
										...{
											[b.measurementType.name]: this.measurementAggregationService.getMeasurement(
												b.measurementType.name,
												program?.tactics,
												b?.measurementType?.aggregations?.primary?.reduceOperation
											),
										},
									};
								}, {}),
							value: this.getSpendByPrograms(programsByRetailer)?.reduce((a, b) => a + b.value, 0),
						};
					}
					return val;
				});
			}
		});

		data = data.map((val) => {
			const programsByRetailer = programs?.filter((p) => p?.retailer?.id === val?.key);
			val = {
				...val,
				children: programsByRetailer.map((program: Program) => {
					return {
						key: program?.id,
						viewValue: program.name,
						meta: program?.tactics.reduce((result, tactic) => {
							tactic.measurements.forEach((measurement) => {
								result = {
									...result,
									[measurement.measurementType.name]: this.measurementAggregationService.getMeasurement(
										measurement.measurementType.name,
										program?.tactics,
										measurement?.measurementType?.aggregations?.primary?.reduceOperation
									),
								};
							});

							return result;
						}, {}),
						value: this.getSpendByTactic(program?.tactics ?? [])?.reduce((a, b) => a + b.value, 0),
						children: program?.tactics?.map((tactic) => {
							return {
								key: tactic?.id,
								viewValue: tactic.name,
								meta: tactic?.measurements.reduce(
									(acc, b) => ({
										...acc,
										[b.measurementType.name]: this.measurementAggregationService.getMeasurement(
											b.measurementType.name,
											[tactic],
											b?.measurementType?.aggregations?.primary?.reduceOperation
										),
									}),
									{}
								),
								value: tactic?.budgetCache?.spendEstimated ?? 0,
							};
						}),
					};
				}),
			};

			return val;
		});

		return data;
	}

	/**
	 * Return estimated spend by tactics vendor
	 */
	getSpendByVendor(tactics: Tactic[]): SnapshotAnalyticsData[] {
		const data: SnapshotAnalyticsData[] = [];

		(tactics ?? [])?.forEach((tactic) => {
			(tactic.vendors ?? []).forEach((vendor) => {
				if (!data.find((val) => val.key === vendor.id)) {
					data.push({
						key: vendor?.id,
						viewValue: vendor.name,
						value: tactics
							.filter((val) => (val.vendors ?? []).find((vend1) => vend1.id === vendor?.id))
							.reduce((previousValue, currentValue) => previousValue + currentValue?.budgetCache.spendEstimated, 0),
					});
				}
			});
		});
		return data;
	}
}
