import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { PermissionLevel } from '../../../global/global.model';
import { GlobalService } from '../../../global/global.service';
import { MilestoneDialogComponent } from '../../milestone/milestone-dialog/milestone-dialog.component';
import { createMilestones, Milestones } from '../../milestone/milestones.model';
import { MilestonesQuery } from '../../milestone/milestones.query';
import { MilestonesService } from '../../milestone/milestones.service';
import { Program } from '../program.model';
import { ProgramQuery } from '../program.query';
import { ProgramService } from '../program.service';

@Component({
	selector: 'app-program-milestone',
	templateUrl: './program-milestones.component.html',
	styleUrls: ['./program-milestones.component.scss'],
})
export class ProgramMilestoneComponent implements OnInit, OnDestroy {
	@Input() program: Program;
	@Input() viewOnly?: boolean;

	public milestones$: Observable<Milestones[]>;
	public program$: Observable<Program>;
	public total: number;
	public differenceToPlannedCost: number;

	public milestoneBrandTotals: { [key: string]: number };
	public costBrandTotals: { [key: string]: number };

	private readonly _unsubscribe$: Subject<void> = new Subject();

	readonly PermissionLevel = PermissionLevel;

	constructor(
		private readonly dialog: MatDialog,
		private readonly milestoneQuery: MilestonesQuery,
		private readonly milestoneService: MilestonesService,
		private readonly programQuery: ProgramQuery,
		private readonly programService: ProgramService,
		private readonly globalService: GlobalService
	) {
		this.program$ = this.programQuery.selectActive();

		this.milestones$ = this.milestoneQuery.selectAll().pipe(
			map((milestones) => milestones.filter((milestone) => milestone.programId === this.program.id && milestone.created)),
			tap((res) => console.log(res))
		);
		combineLatest([this.milestoneQuery.selectAll()])
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe(
				([milestones]) => {
					console.log(milestones);
					// Keep our program updated with milestones
					if (this.program?.id) {
						this.programService.update(this.program.id, { milestones: this.milestoneQuery.getAll() }, true);
					}
				},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	ngOnInit(): void {
		console.log('PROGRAM milestone', this.program);
		this.milestoneService.get(this.program.id, { type: 'default' }).pipe(first(), takeUntil(this._unsubscribe$)).subscribe();
		this.milestoneService.set(this.program.milestones || []);
	}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	createBlank(): void {
		const newMilestone = createMilestones({ programId: this.program.id });
		this.milestoneService.add(newMilestone);
		this.openMilestoneDialog(newMilestone, true);
	}

	openMilestoneDialog(milestone: Milestones, createMode = false): void {
		console.log('Opening milestone dialog', milestone);
		if (this.viewOnly) return;

		this.dialog.open(MilestoneDialogComponent, {
			data: {
				milestone,
				program: this.program,
				createMode,
			},
			disableClose: false,
			panelClass: ['fullscreen', 'background-color', 'max-width-md'],
		});
	}

	create(milestone: Milestones): void {
		this.milestoneService
			.create(this.program.id, milestone)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(response) => {
					// Add to our program in Akita
					this.programService.update(this.program.id, { milestones: this.milestoneQuery.getAll() }, true);
				},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	remove(milestone: Milestones): void {
		console.log(milestone);
		this.milestoneService
			.remove(this.program.id, milestone.id)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				(response) => {
					// Add to our program in Akita
					this.programService.update(this.program.id, { milestones: this.milestoneQuery.getAll() }, true);
					console.log(response);
				},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	getDifference(num1: number, num2: number): number {
		return (num1 - num2) * -1;
	}
}
