<div mat-dialog-title fxLayout="column">
	<div class="row" fxLayout="row">
		<h3>{{ briefTitle(dialogData.deliverable) }}</h3>
		<div class="actions margin-left-auto">
			<button [mat-dialog-close]="false" mat-flat-button class="button-extra-small marign-left-auto" color="tertiary">Close</button>
		</div>
	</div>

	<span class="font-size-small">
		Please complete the required fields. Once the brief is submitted, it will be sent to your Account lead for review and internal kick
		off.
	</span>
</div>

<div mat-dialog-content class="column" fxLayout="column">
	<ng-container *ngIf="!loading; else showLoader">
		<ng-container
			*ngIf="supportedBriefs.includes(dialogData?.deliverable?.slug); else noTemplate"
			[ngSwitch]="dialogData?.deliverable?.slug"
		>
			<ng-container *ngSwitchCase="'vml-strategy-brief'">
				<app-strategic-brief-dialog-default
					[program]="program"
					[deliverable]="dialogData.deliverable"
					[section]="dialogData.section"
					(milestonesUpdated)="onMilestonesUpdate($event)"
					(onAddTacticClicked)="onAddTactic()"
					(onEditBudgetClicked)="onEditBudget()"
				></app-strategic-brief-dialog-default>
			</ng-container>

			<ng-container *ngSwitchCase="'nestle-retailer-activation-brief'">
				<app-strategic-brief-dialog-default
					[program]="program"
					[deliverable]="dialogData.deliverable"
					[section]="dialogData.section"
					(milestonesUpdated)="onMilestonesUpdate($event)"
					(onAddTacticClicked)="onAddTactic()"
					(onEditBudgetClicked)="onEditBudget()"
				></app-strategic-brief-dialog-default>
			</ng-container>

			<div *ngSwitchDefault="">N/A</div>
		</ng-container>

		<ng-template #noTemplate>
			<app-empty-state
				class="full-width"
				title="Couldn't find any brief types."
				description="A brief form is not available for the selected brief type. Please contact your administrator for more details."
			></app-empty-state>
		</ng-template>

		<div class="mt-6" *ngIf="supportedBriefs.includes(dialogData?.deliverable?.slug)">
			<div fxLayoutAlign="center" class="full-width" *ngIf="showInvalidFormError">
				<mat-error class="mb-2">All required fields must be filled out before submitting the brief.</mat-error>
			</div>
			<div class="row" fxLayout="row" style="justify-content: space-between">
				<button [mat-dialog-close]="false" mat-flat-button class="button-extra-small" color="tertiary">Cancel</button>
				<div class="column" fxLayout="column">
					<button mat-button mat-flat-button class="button-extra-small mb-1" (click)="onSave()">Submit Brief</button>
					<span class="mt-1 font-size-xs" *ngIf="program?.programStrategicBrief?.versionDate"
						>Last submitted: {{ program?.programStrategicBrief?.versionDate | date: "dd MMMM yyyy 'at' h:mm a" }}</span
					>
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #showLoader>
	<div class="loading">
		<mat-spinner [diameter]="40"></mat-spinner>
	</div>
</ng-template>

<ng-template #dynamic></ng-template>
