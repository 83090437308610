import { HttpErrorResponse } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxMasonryComponent } from 'ngx-masonry';
import { concatMap, first, take, takeUntil } from 'rxjs/operators';
import { FileReviewStatus, PublicFile } from '../../../../../../api/src/file/file.entity';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Entities, EntityContainerMode, UserRole } from '../../../state/global/global.model';
import { GlobalService } from '../../../state/global/global.service';
import { GlobalQuery } from '../../../state/global/global.query';
import { Plan } from '../../../state/entities/plan/plan.model';
import { Program } from '../../../state/entities/program/program.model';
import { FileModalComponent } from '../../../state/entities/file/file-modal/file-modal.component';
import {
	FilePendingReviewersResult,
	FilePendingReviewerType,
	FileReviewersDialogComponent,
	FileReviewersDialogData,
	ReviewerRole,
} from '../../../state/entities/file/file-reviewers-dialog/file-reviewers-dialog.component';
import { FileUploadDialogComponent } from '../../../state/entities/file/file-upload-dialog/file-upload-dialog.component';
import { FileService } from '../../../state/entities/file/file.service';
import { Tactic } from '../../../state/entities/tactic/tactic.model';
import { FileReviewUtils } from '../../../../../../api/src/file/utils/file-review.utils';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionQuery } from '../../../state/session/session.query';
import { from, Subject } from 'rxjs';
import { User } from '../../../state/entities/user/user.model';

@Component({
	selector: 'app-file-list',
	templateUrl: './file-list.component.html',
	styleUrls: ['./file-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListComponent implements OnInit, OnChanges, OnDestroy {
	@Input() tactics: Tactic[];
	@Input() files: PublicFile[];
	@Input() showActions = true;
	@Input() showEntityName = false;
	@Input() relayout = false;
	@Input() mode: EntityContainerMode;
	@Input() readOnly = false;
	@Input() entity: Program | Plan;
	@Input() entityType: Entities;
	@Input() showFileReviewStatus: boolean;
	@Input() fileReviewsEnabledOverride: boolean;
	@Input() hideEdit: boolean = false;

	@Output() removeConfirmed = new EventEmitter<PublicFile>();

	@ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;

	public updateMasonryLayout = false;
	public fileReviewsEnabled = false;
	readonly FileReviewUtils = FileReviewUtils;
	readonly FileReviewStatus = FileReviewStatus;
	readonly UserRole = UserRole;
	private areFilesUpdated: boolean;
	authUserRole: UserRole;

	private readonly _unsubscribe$: Subject<void> = new Subject<void>();

	constructor(
		public dialog: MatDialog,
		public fileService: FileService,
		private readonly globalQuery: GlobalQuery,
		private readonly globalService: GlobalService,
		private readonly cdr: ChangeDetectorRef,
		private readonly router: Router,
		private readonly sessionQuery: SessionQuery,
		private readonly route: ActivatedRoute
	) {}

	ngOnDestroy(): void {
		this._unsubscribe$.next();
		this._unsubscribe$.complete();
	}

	ngOnInit(): void {
		this.globalQuery.authenticatedSettings$.pipe(take(1), takeUntil(this._unsubscribe$)).subscribe((settings) => {
			this.fileReviewsEnabled = this.globalQuery.getOrganizationSetting('fileReviewsEnabled');
		});

		this.authUserRole = this.sessionQuery.getRole();

		// Check if the URL contains 'fileId' and get its value
		this.route.queryParams.pipe(first(), takeUntil(this._unsubscribe$)).subscribe((params) => {
			if (params['fileId']) {
				const fileExists = this.files.find((file) => file.id === params['fileId']);
				if (fileExists) {
					this.showFileModal(fileExists);
				}
			}
		});
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.relayout && this.masonry) {
			this.masonry.reloadItems();
			this.masonry.layout();
			this.reLayout();
		}

		if (changes?.files?.previousValue?.length === changes?.files?.currentValue?.length) {
			this.areFilesUpdated = true;
		}
	}

	showFileModal(file: any): void {
		if (this.authUserRole === UserRole.Analyst) {
			return;
		}

		const dialogRef = this.dialog.open(FileModalComponent, {
			data: {
				id: this.entity?.id || file?.programId || file?.planId,
				entityType: this.entityType,
				file,
				showGoToProgram: true,
			},
			disableClose: false,
			panelClass: ['fullscreen', 'stretch'],
		});

		dialogRef.afterClosed().pipe(takeUntil(this._unsubscribe$)).subscribe();
	}

	reLayout(): void {
		if (this.areFilesUpdated) {
			this.masonry.reloadItems();
			this.masonry.layout();
			this.areFilesUpdated = false;
			return;
		}
		this.updateMasonryLayout = !this.updateMasonryLayout;
		this.cdr.detectChanges();
	}

	isImage(file: PublicFile): boolean {
		return this.fileService.isImage(file.mimeType);
	}

	getFileTypeIcon(mimeType: string): string {
		return this.fileService.getFileTypeIcon(mimeType);
	}

	download(file: PublicFile): void {
		this.fileService
			.download(file.path, true, file.name)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				() => {},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	edit(file: PublicFile): void {
		const referencePath = this.entityType === 'program' ? 'tactic' : 'program';
		const dialogRef = this.dialog.open(FileUploadDialogComponent, {
			data: { file, referencePath, tactics: this.tactics },
			disableClose: true,
			panelClass: ['fullscreen', 'max-width-lg'],
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((result) => {
				console.log(result);
			});
	}

	addToReview(file: PublicFile): void {
		this.reviewersModal(file, true);
	}

	removeFromReview(file: PublicFile): void {
		this.fileService
			.removeFromReview(file)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				() => {},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	reviewersModal(file: PublicFile, isInitialReview: boolean = false): void {
		const dialogRef = this.dialog.open(FileReviewersDialogComponent, {
			data: { file, isInitialReview, previewOnly: isInitialReview, autoAddSelfAsReviewer: true } as FileReviewersDialogData,
			panelClass: ['fullscreen', 'background-color', 'max-width-sm'],
			disableClose: false,
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((result) => {
				if (result?.length) {
					this._saveAndNotifyPendingReviewers(result, file);
				}
			});
	}

	getUserReviewStatus(file: PublicFile): FileReviewStatus {
		const userId = this.sessionQuery.getValue().profile.id;
		return FileReviewUtils.getUserReviewStatus(userId, file);
	}

	getReviewStatus(file: PublicFile): FileReviewStatus {
		return FileReviewUtils.getReviewStatus(file) as FileReviewStatus;
	}

	removeModal(file: PublicFile): void {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				entityName: 'File',
				remove: true,
			},
			disableClose: false,
		});

		dialogRef
			.afterClosed()
			.pipe(takeUntil(this._unsubscribe$))
			.subscribe((result) => {
				if (result) {
					this.removeConfirmed.emit(file);
					this.remove(file);
				}
			});
	}

	remove(file: PublicFile): void {
		this.fileService
			.remove(file)
			.pipe(first(), takeUntil(this._unsubscribe$))
			.subscribe(
				() => {},
				(err: HttpErrorResponse) => this.globalService.triggerErrorMessage(err)
			);
	}

	trackByFn(index, item): any {
		return item.id || item?.path;
	}

	navigateToProgram(programId: string, fileId: string): void {
		const role = this.sessionQuery.getValue().profile.role;
		if (role === 'guest') {
			this.router.navigate(['/file-review', programId], { fragment: fileId });
		} else {
			this.router.navigate(['/program', programId, 'files'], {
				queryParams: { panel: 'file-reviews' },
				fragment: fileId,
			});
		}
	}

	private _saveAndNotifyPendingReviewers(result: FilePendingReviewersResult[], file: PublicFile): void {
		const addOrRemoveReviewersObserverActions$ = [];
		const filePendingReviewers = result as FilePendingReviewersResult[];
		if (filePendingReviewers?.length) {
			const squashedPendingReviewersForBatch = this._squashPendingReviewersForBatch(
				filePendingReviewers.filter((val) => val.type === FilePendingReviewerType.CREATE)
			) as FilePendingReviewersResult[] | { approvers }[];

			squashedPendingReviewersForBatch.forEach((val) => {
				if (val.type === FilePendingReviewerType.CREATE) {
					addOrRemoveReviewersObserverActions$.push(
						this.fileService.addReviewersBatch(file, val.approvers, val.reviewers, val.message, val.feedbackDueDate)
					);
				}
			});

			filePendingReviewers.forEach((reviewDialogResult) => {
				if (reviewDialogResult?.type === FilePendingReviewerType.REMOVE) {
					addOrRemoveReviewersObserverActions$.push(
						this.fileService.removeReviewers(file, [reviewDialogResult.userId], reviewDialogResult.role)
					);
				}
			});

			from(addOrRemoveReviewersObserverActions$)
				.pipe(
					concatMap((observable) => observable.pipe()),
					takeUntil(this._unsubscribe$)
				)
				.subscribe(
					(results) => {},
					(error) => {
						this.globalService.triggerErrorMessage(error);
					},
					() => {
						this.globalService.triggerSaveMessage('Reviewers added & notified successfully');
					}
				);
		}
	}

	private _squashPendingReviewersForBatch(originalItems: FilePendingReviewersResult[]): FilePendingReviewersResult[] & { approvers }[] {
		const mergedItemsMap: Map<string, FilePendingReviewersResult & { approvers: User[] }> = new Map();

		for (const originalItem of originalItems) {
			const type = originalItem.type || '';

			if (!mergedItemsMap.has(type)) {
				mergedItemsMap.set(type, {
					...originalItem,
					approvers: [],
				});
			}

			const mergedItem = mergedItemsMap.get(type);

			if (originalItem.role === ReviewerRole.Reviewer || !originalItem.role) {
				if (originalItem.reviewers && originalItem.reviewers.length > 0) {
					if (!mergedItem.reviewers) {
						mergedItem.reviewers = [];
					}
					mergedItem.reviewers.push(...originalItem.reviewers);
				}
			} else if (originalItem.role === ReviewerRole.Approver) {
				if (originalItem.reviewers && originalItem.reviewers.length > 0) {
					if (!mergedItem.approvers) {
						mergedItem.approvers = [];
					}
					mergedItem.approvers.push(...originalItem.reviewers);
				}
			}
		}

		// Remove approvers from the reviewers array
		for (const mergedItem of mergedItemsMap.values()) {
			if (mergedItem.reviewers && mergedItem.approvers) {
				mergedItem.reviewers = mergedItem.reviewers.filter(
					(reviewer) => !(mergedItem.approvers ?? [])?.some((approver) => approver.email === reviewer.email)
				);
			}
		}

		return Array.from(mergedItemsMap.values());
	}

	goToProgram(id: string): void {
		const appSection = this.globalQuery.getAppSection();
		if (appSection === 'media-plan') {
			this.router.navigate(['/media-plan', id]);
		} else {
			this.router.navigate(['/program', id]);
		}
	}

	goToTactic(programId: string, tacticId: string): void {
		const appSection = this.globalQuery.getAppSection();
		if (appSection === 'media-plan') {
			this.router.navigate(['/media-plan', programId, 'tactic', tacticId]);
		} else {
			this.router.navigate(['/program', programId, 'tactic', tacticId]);
		}
	}
}
